import React, { useEffect } from "react";
import UserList from "../../components/UserList/UserList";
import AttachReportsForm from "../../components/AssignReportsForm/AttachReportsForm";
import { useGetUserApiQuery } from "../../redux/api/createUserApi";
import {
  useAssignCustomReportMutation,
  useLazyGetSearchReportQuery,
} from "../../redux/api/assignReportApi";
import { toast } from "react-toastify";
import Preloader from "../../components/Preloader/Preloader";
import ReportAssignment from "../ReportAssignment";
import ReviewTheDataCards from "../ReportAssignment/ReviewTheDataCards";
import { useDispatch } from "react-redux";
import { resetAll } from "../../redux/libraryAssignmentMain";
import { useLocation, useRoutes } from "react-router-dom";

const AssignReport = () => {
  const [currentView, setCurrentView] = React.useState("user-list");
  const [modalVisible, setModalVisible] = React.useState(false);
  const { data: userData, error, isLoading, isFetching } = useGetUserApiQuery();
  const [scratchReports, setScratchReports] = React.useState("");
  const [selectedUserEmail, setSelectedUserEmail] = React.useState("");
  const [selectedUserName, setSelectedUserName] = React.useState("");
  const [selectedCustomReportUser, setSelectedCustomReportUser] = React.useState(false);
  const [selectedUserHasSemiAccess, setSelectedUserHasSemiAccess] = React.useState(false);
  const [showReportAssignment, setShowReportAssignment] = React.useState(true);
  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleCurrentView = (id, email, name, customUser, semiAccess) => {
    if (email !== selectedUserEmail) {
      dispatch(resetAll());
    }
    console.log("id", id, semiAccess);
    setCurrentView(id);
    setSelectedUserEmail(email);
    setSelectedUserName(name);
    setSelectedCustomReportUser(customUser);
    setSelectedUserHasSemiAccess(semiAccess);
  };

  useEffect(() => {
    if (state?.userEmail) {
      handleCurrentView(state.userId, state.userEmail, state.userName, state.customUser, state.semiAccess);
    }
  }, [state?.userEmail, state?.userId]);

  const [
    getSearchReport,
    {
      data: searchReportData,
      error: searchReportError,
      isLoading: searchReportLoading,
    },
  ] = useLazyGetSearchReportQuery();


  const navigateToUserList = () => {
    setCurrentView("user-list");
  };


  const [
    assignCustomReport,
    {
      data: assignCustomReportData,
      error: assignCustomReportError,
      isLoading: assignCustomReportLoading,
    },
  ] = useAssignCustomReportMutation();

  const handleModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  const searchReportTitles = (value, searchBy) => {
    getSearchReport({ searchText: value, search_by: searchBy });
  };
  const handleBack = () => {
    setCurrentView("user-list");
  };

  useEffect(() => {
    if (currentView === 'user-list') { 
      sessionStorage.setItem('callApi', true);
    }
  }, [currentView])
  
  const handleBackReportAssignment = (caseValue) => {
    setShowReportAssignment(caseValue);
  };

  const handleAssignReports = (data) => {
    const getReportId = scratchReports?.split(":")[1];
    assignCustomReport({
      user_id: currentView,
      report_id: getReportId,
    })
      .then((res) => {
        if (res?.data?.message) {
          setCurrentView("user-list");
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        toast.error(res?.error?.data?.message, {
          position: "top-center",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        toast.error(err?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  if (isLoading || isFetching || assignCustomReportLoading)
    return <Preloader />;

  return currentView === "user-list" ? (
    <>
    <div >
      <UserList
        data={userData?.data}
        from="assign-reports"
        getUserReports={handleCurrentView}
      />
      </div>
    </>
  ) : (
    <>
      {showReportAssignment ? (
        <ReportAssignment
            handleBack={handleBack}
            handleBackReportAssignment={() => handleBackReportAssignment(false)}
            selectedUserEmail={selectedUserEmail}
            selectedUserName={selectedUserName}
            selectedCustomReportUser={selectedCustomReportUser}
            selectedUserHasSemiAccess={selectedUserHasSemiAccess}
        />
      ) : (
        <ReviewTheDataCards
              selectedUserEmail={selectedUserEmail}
              handleBackReportAssignment={() => handleBackReportAssignment(true)}
              navigateToUserList={navigateToUserList}
              selectedUserName={selectedUserName}
        />
      )}
      {/* <AttachReportsForm
        searchReportResults={searchReportData}
        searchReportTitles={searchReportTitles}
        handleBack={handleBack}
        handleAssignReports={handleAssignReports}
        scratchReports={scratchReports}
        setScratchReports={setScratchReports}
      /> */}
    </>
  );
};

export default AssignReport;
