import React, { useState } from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { useJwt } from "react-jwt";
import Cookies from "universal-cookie";
import axios from "axios";
import API from "../../utils/api";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Card, Input, Button, Typography } from 'antd';

const { Title, Text } = Typography;

// import NavBar from "../../components/NavBar";
// import Header from "../../components/Header";

function Settings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errors, setErrors] = useState({
    newPasswordError: "",
    confirmNewPasswordError: "",
  });
  const [disabled, setDisabled] = useState(true);

  const { Base_URL, resetPassword } = API;
  const navigate = useNavigate();

  const cookies = new Cookies();
  let token = cookies.get("TOKEN");

  const { decodedToken } = useJwt(token);

  const isNavBarShrink = useSelector((store) => store.interface.isNavBarShrink);

  const handlePasswords = (event) => {
    const { name, value } = event.target;
    if (name === "newPassword") {
      setNewPassword(value);

      if (value?.length < 8) {
        setErrors({
          ...errors,
          newPasswordError: "Password should have minimum 8 character length.",
        });
      } else {
        setErrors({
          ...errors,
          newPasswordError: "",
        });
      }
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
      if (!newPassword?.length) {
        setErrors({
          ...errors,
          confirmNewPasswordError: "Enter New Password Firstly.",
        });
        setDisabled(true);
      } else if (value === newPassword) {
        setErrors({
          ...errors,
          confirmNewPasswordError: "",
        });
        setDisabled(false);
      } else {
        setErrors({
          ...errors,
          confirmNewPasswordError:
            "Confirm password should be same as New Password.",
        });
        setDisabled(true);
      }
    } else if (name === "oldPassword") {
      setOldPassword(value);
    }
  };

  const changePassword = () => {
    let args = {
      message: "Requesting",
      description: `Wait a moment`,
      duration: 2,
      placement: "topRight",
    };
    notification.warning(args);

    let config = {
      method: "post",
      headers: { authorization: `Bearer ${token}` },
      url: `${Base_URL}${resetPassword}`,
      data: {
        old_password: oldPassword,
        new_password: newPassword,
      },
    };

    axios(config)
      .then((response) => {
        let args = {
          message: "Success",
          description: `${response?.data?.message || "Password Changed."}`,
          duration: 2,
          placement: "topRight",
        };
        notification.success(args);
        cookies.remove("TOKEN");
        navigate("/");
      })
      .catch((error) => {
        let args = {
          message: "Failed",
          description: `${error?.response?.data?.message || "Something went wrong."
            }`,
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
      });
  };

  //  if(newPassword?.length >=8 && confirmNewPassword?.length && confirmNewPassword === newPassword){
  //   setDisabled(true)
  //  }

  return (
    <>
      {/* <h3>Settings</h3> */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: "8%" }}>
        <Card
          title={<Title level={4}>User Profile</Title>}
          bordered={false}
          style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}
        >
          <div className={styles.userDetails}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Text strong>Name: </Text>
              <Text>{decodedToken?.name}</Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
              <Text strong>Email: </Text>
              <Text>{decodedToken?.email}</Text>
            </div>
          </div>

          <Title level={5}>Change Password</Title>
          <Input
            name="oldPassword"
            type="password"
            placeholder="Enter Current Password"
            value={oldPassword}
            onChange={handlePasswords}
            style={{ marginBottom: '10px' }}
          />
          <Input
            name="newPassword"
            type="password"
            placeholder="Enter New Password"
            value={newPassword}
            onChange={handlePasswords}
            style={{ marginBottom: '10px' }}
          />
          <Text type="danger">{errors?.newPasswordError}</Text>
          <Input
            name="confirmNewPassword"
            type="password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={handlePasswords}
            style={{ marginBottom: '10px' }}
          />
          <Text type="danger">{errors?.confirmNewPasswordError}</Text>
          <div style={{ textAlign: 'center'}}>
            <Button
              type="primary"
              disabled={disabled}
              onClick={changePassword}
              style={{ marginTop: '10px' }}
            >
              Update Password
            </Button>
          </div>
        </Card>
        <div>
          {/* <div className={styles.userDetails}> */}
          {/* <h4>User Profile</h4>
            <div style={{display:'flex',alignItems:'center'}}>
          <p>Name :&nbsp;</p>
              <p>{decodedToken?.name}</p>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
          <p>Email :&nbsp;</p>
          <p>{decodedToken?.email}</p>
            </div>  */}
          {/* <p>Status : </p>
      <p>{decodedToken?.active === true ? 'Active' : 'Not Active'}</p> */}
          {/* </div> */}

          {/* <div className={styles.changePassword}>
          <h4>Change Password</h4>
          <input
            name="oldPassword"
            type="text"
            placeholder="Enter Current Password"
            value={oldPassword}
            onChange={handlePasswords}
          />
          <small></small>
          <input
            name="newPassword"
            type="text"
            placeholder="Enter New Password"
            value={newPassword}
            onChange={handlePasswords}
          />
          <small>{errors?.newPasswordError}</small>
          <input
            name="confirmNewPassword"
            type="text"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={handlePasswords}
          />
          <small>{errors?.confirmNewPasswordError}</small>
          <button
            className={disabled ? styles.disablebutton : styles.enablebutton}
            onClick={changePassword}
          >
            {" "}
            Update Password
          </button>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default Settings;
