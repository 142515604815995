// src/pages/NotFound.jsx
import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <Typography variant="h1" component="h2" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" component="h3" gutterBottom>
        Oops! The page you are looking for does not exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/home/dashboard"
      >
        Back to Dashboard
      </Button>
    </div>
  );
};

export default NotFound;
