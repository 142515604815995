import { Button, Input, Space, Switch, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import {
  useGetAllPowerBiQuery,
  useEditPowerBiMutation,
} from "../../redux/api/assignReportApi";
import PowerBI from ".";
import { EditOutlined } from "@ant-design/icons";
import Loader from "../../components/Common/Loader/Loading";
import { toast } from "react-toastify";
import { render } from "@testing-library/react";

const EditPowerBI = () => {
  const [currentEditData, setCurrentEditData] = useState();
  const [clientList, setClientList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState('')

  const { data: powerBiData, isLoading: powerBiLoading } =
    useGetAllPowerBiQuery();

  const handleGoBack = () => {
    setIsEdit(false);
  };

  const [
    editPowerBi,
    {
      data: editPowerBiData,
      error: editPowerBiError,
      isLoading: editPowerBiLoading,
    },
  ] = useEditPowerBiMutation();

  const handleEdit = (data) => {
    editPowerBi({
      ...data,
      is_active: !data.is_active,
    })
      .then((res) => {
        if (res.error) {
          toast.error(res.error.message);
        } else {
          toast.success("PowerBI updated successfully!");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    return;
  }

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "client_email",
      key: "client_email",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size={"middle"}>

          <Tooltip title="Edit Dashboard">
            <Button
              type="primary"
              ghost
              style={{ background: "white" }}
              icon={<EditOutlined />}
              onClick={() => {
                setCurrentEditData(record);
                setIsEdit(true);
              }}
            />
          </Tooltip>
          <Tooltip title={record.is_active ? "Deactivate Dashboard" : "Activate Dashboard"}>
            <Button
              danger={record.is_active}
              onClick={() => handleEdit(record)}
              style={{
                width: "100px",
                borderColor: record.is_active ? "" : "green",
                color: record.is_active ? "" : "green",
                backgroundColor: record.is_active ? "" : "white",
              }}
            >
              {record.is_active ? "Deactivate" : "Activate"}
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];


  useEffect(() => {
    setClientList(powerBiData?.data)
  }, [powerBiData])

  const handleChange = (e) => {
    const val = e.target.value;
    setText(val)
    const filterData = powerBiData?.data.filter(item => item.client_email.includes(val));
    setClientList(filterData);
  }

  if (isEdit) {
    return <PowerBI dashboardData={currentEditData} goBack={handleGoBack} />;
  }
  return (<>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "1rem",
      }}
    >
      <Input
        style={{
          width: "20%",
          marginLeft: "auto",
          //Highlight the search bar
          border: "1px solid #67bfff",
        }}
        value={text}
        placeholder="Search Client"
        onChange={handleChange}
      />
    </div>
    <Table
      loading={powerBiLoading}
      className="custom-table"
      columns={columns}
      dataSource={clientList}
    />
  </>
  );
};

export default EditPowerBI;
