import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { notification } from "antd";

import styles from "./styles.module.css";
import API from "../../utils/api";

import { LinearProgress } from "@mui/material";

import PasswordVisibilityInput from "../../components/Common/PasswordVisibiltyInput";
import LabelInputField from "../../components/Common/LabelInputField";

const initialState = {
  email: "",
  password: "",
};

const errorsState = { err_email: "", err_password: "" };

function Login() {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(errorsState);

  const [loading, setLoading] = useState("IDEAL");
  const { email, password } = formState;
  const { Base_URL, logIn } = API;

  const cookies = new Cookies();

  const navigate = useNavigate();

  const handleFormChange = (event) => {
    setFormState((previousState) => ({
      ...previousState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const email = formState.email,
      password = formState.password;

    let error = {};

    if (!emailRegex.test(email)) {
      error = {
        ...error,
        err_email: "Invalid Email.",
      };
      setErrors(error);
    }

    if (!password || password.length < 8) {
      error = {
        ...error,
        err_password: "The password you entered doesn’t have 8 characters.",
      };
      setErrors(error);
    }

    if (Object.keys(error).length) return;

    setLoading("PENDING");
    axios
      .post(`${Base_URL}${logIn}`, formState)
      .then((response) => {
        const { message, token, user } = response.data;
        if (message === "Success") {
          let args = {
            message: "Successfully Logged In.",
            description: "You have been logged in successfully.",
            duration: 2,
            placement: "topRight",
          };
          notification.success(args);
          cookies.set("TOKEN", token, { path: "/", maxAge: 60 * 60 * 24 });
          cookies.set("ROLE", user.designation, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          cookies.set("NAME", user.name, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          cookies.set("EMAIL", user.email, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });
          cookies.set("ISPARTNER", user.isPartner, {
            path: "/",
            maxAge: 60 * 60 * 24,
          });

          localStorage.setItem("ROLE", JSON.stringify(user.designation));
          // cookies.set("ROLE", "SALES_EXECUTIVE", {
          //   path: "/",
          //   maxAge: 60 * 60 * 24,
          // });
          if (
            user.designation === "SALES_EXECUTIVE" ||
            user.designation === "SUPER_ADMIN" ||
            user.designation === "SALES_MANAGER"
          ) {
            navigate("/home/dashboard", { replace: true });
          } else if (
            user.designation === "FORMATTING_MANAGER" ||
            user.designation === "FORMATTING_EXECUTIVE"
          ) {
            navigate("/home/content-management/view-and-edit-reports", {
              replace: true,
            });
          } else {
            navigate("/home", { replace: true });
          }
        } else if (message === "User not found, please register") {
          let args = {
            message: "Invalid User",
            description: message,
            duration: 2,
            placement: "topRight",
          };
          notification.error(args);
        } else if (message === "Please enter correct password") {
          let args = {
            message: "Wrong Password",
            description: message,
            duration: 2,
            placement: "topRight",
          };
          notification.error(args);
        }
      })
      .catch((err) => {
        setErrors(errorsState);
        let args = {
          message: "Login Failed",
          description: err?.message,
          duration: 2,
          placement: "topRight",
        };
        notification.error(args);
      })
      .finally(() => {
        setLoading("IDEAL");
      });

    return;
  };

  return (
    <div className={styles.detailsBox}>
      <h1>Login</h1>
      <div className={styles.inputContainer}>
        <form onSubmit={handleFormSubmit}>
          <LabelInputField>
            <LabelInputField.Label>
              Email
              <LabelInputField.Tooltip value={"Enter your registered email."} />
            </LabelInputField.Label>
            <LabelInputField.Input
              type="text"
              name="email"
              placeholder="Enter your registered email."
              value={email}
              onChange={(event) => handleFormChange(event)}
            />
            <LabelInputField.Error errors={errors.err_email} />
          </LabelInputField>
          <LabelInputField>
            <LabelInputField.Label>
              Password
              <LabelInputField.Tooltip value={"Enter your password."} />
            </LabelInputField.Label>
            <LabelInputField.CustomInput>
              <PasswordVisibilityInput
                type="password"
                name="password"
                placeholder="Enter your password."
                value={password}
                onChange={(event) => handleFormChange(event)}
              />
            </LabelInputField.CustomInput>
            <LabelInputField.Error errors={errors.err_password} />
          </LabelInputField>

          <div className={styles.loginContainer}>
            <button type="submit">Login</button>
            {loading === "PENDING" && <LinearProgress />}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
