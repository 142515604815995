import { Box, Step, StepButton, Stepper } from "@mui/material";

import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import API from "../../utils/api";
import _ from "lodash";
import {
  useLazyGetLibraryReportsQuery,
  useUpdateUserDataMutation,
} from "../../redux/api/createUserApi";
import { useDispatch, useSelector } from "react-redux";

import { ArrowForwardIos } from "@mui/icons-material";
import {
  resetAll,
  updateLibraryFormDetails,
  updateSelectedReport,
} from "../../redux/libraryAssignmentMain";

import {
  Form,
  Select,
  DatePicker,
  Button,
  Table,
  Col,
  Row,
  Input,
  Switch,
} from "antd";
import SelectedCard from "./SelectedCard";
import { toast } from "react-toastify";
import Fuse from "fuse.js";
import { newReportAssignmentData } from "../../mock/newReportAssignmentData";
import { findMatches } from "./utils";

const { Option } = Select;

const accessTypeList = [
  // {
  //   id: "trial",
  //   name: "Trial",
  // },
  {
    id: "limited",
    name: "Limited",
  },
  {
    id: "full",
    name: "Full",
  },
];

function LibraryCreate({ userEmail, selectedCustomReportUser, selectedUserHasSemiAccess }) {
  const [form] = Form.useForm();
  const selectedReportData = useSelector(
    (state) => state.libraryAssignmentMain.selectedReport
  );

  const formDetails = useSelector(
    (state) => state.libraryAssignmentMain.libraryFormDetails
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [selected, setSelected] = React.useState([]);

  const [hubList, setHubList] = React.useState([]);
  const cookies = new Cookies();
  let token = cookies.get("TOKEN");
  const [selectedRowData, setSelectedRowData] = React.useState([]);

  const hubAccessType = Form.useWatch("hubAccessType", form);
  const { Base_URL, getHubList } = API;
  let config = {
    method: "get",
    headers: { authorization: `Bearer ${token}` },
    maxBodyLength: Infinity,
    url: `${Base_URL}${getHubList}`,
  };

  useEffect(() => {
    axios(config).then((res) => {
      const hubList = res?.data?.data;
      const sortedData = _.sortBy(hubList, "name");
      setHubList(sortedData);
    });
  }, []);

  useEffect(() => {
    if (selectedReportData.length > 0) {
      setSelected(selectedReportData.map((item) => item.id));
      setSelectedRowData(selectedReportData);
    }
    //Check if formDetails is not empty
    if (Object.keys(formDetails).length > 0) {
      form.setFieldsValue(formDetails);
    }
  }, []);

  const [trigger, result] = useLazyGetLibraryReportsQuery();
  const {
    data: reportData,
    isLoading: reportDataLoading,
    isError: reportDataError,
  } = result;

  const [updateUser, updateResult] = useUpdateUserDataMutation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [rowData, setRowData] = React.useState([]);
  const [fullData, setFullData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalReport, setTotalReport] = React.useState(0);
  const [finalSearchText, setFinalSearchText] = React.useState("");
  const [mainLoader, setMainLoader] = React.useState(false);
  const [customReports, setCustomReports] = React.useState(
    selectedCustomReportUser
  );
  const [query, setQuery] = React.useState("");
  const fuse = new Fuse(rowData, {
    keys: ["title"],
    includeScore: true,
  });
  const dispatch = useDispatch();

  const handleDateChange = (date, index, key, report_id) => {
    const updatedRowData = [...rowData];
    updatedRowData[index] = { ...updatedRowData[index], [key]: date };
    //Modify selectedCloneRowData
    const modifedSelectedRowData = selectedRowData.map((item) => {
      return item.id === report_id ? { ...item, [key]: date } : item;
    });

    setRowData(updatedRowData);
    if (query?.length > 1) {
      dispatch(
        updateSelectedReport({
          data: modifedSelectedRowData,
          isSearch: true,
          searchSource: filteredData,
          exitingStateData: selectedReportData,
        })
      );
    } else {
      dispatch(updateSelectedReport({ data: modifedSelectedRowData }));
    }
    setSelectedRowData(modifedSelectedRowData);
  };

  const [filteredData, setFilteredData] = useState(rowData);

  useEffect(() => {
    if (hubAccessType === "limited") {
      (async () => {
        setMainLoader(true);
        try {
          await trigger({
            limit: 10000000000,
            page: 1,
            customReports,
            // searchText: finalSearchText,
            // hubId: 6,
          }).then((res) => {
            setTotalReport(res?.data?.meta?.report_count);
          });
        } catch (e) {
          console.log("ERROR:", e);
        } finally {
          setMainLoader(false);
        }
      })();
    }
  }, [hubAccessType, customReports, selectedUserHasSemiAccess]);
  const [selectedIds, setSelectedIds] = useState([[]]); // Initialize with an empty array for each level

  useEffect(() => {
    if (query.trim() === "") {
      setTotalReport(rowData?.length);

      setFilteredData(rowData);
    } else {
      const searchResult = fuse.search(query).map((result) => result.item);
      setTotalReport(searchResult?.length);

      setFilteredData(searchResult);
    }
  }, [query, rowData]);

  useEffect(() => {
    if (query.trim() === "") {
      //I want that record which are present in selectedReportData and in searchResult
      const selectedReportDataClone = [...selectedReportData];
      const updatedSelectedReportData = selectedReportDataClone.filter((item) =>
        rowData.some((searchItem) => searchItem.id === item.id)
      );
      setSelectedRowData(updatedSelectedReportData);
      setSelected(updatedSelectedReportData.map((item) => item.id));
    } else {
      const selectedReportDataClone = [...selectedReportData];
      const updatedSelectedReportData = selectedReportDataClone.filter((item) =>
        filteredData.some((searchItem) => searchItem.id === item.id)
      );
      setSelectedRowData(updatedSelectedReportData);
      setSelected(updatedSelectedReportData.map((item) => item.id));
    }
  }, [filteredData, query]);

  useEffect(() => {
    if (reportData?.data) {
      const copyData = [...reportData.data];
      const newData = copyData.map((item) => {
        //If selectedRowData have same report then set the expiry and subscription date
        const selectedReport = selectedReportData.find((selected) => {
          return selected.synapse_report_id === item.synapse_report_id;
        });
        if (selectedReport) {
          return {
            ...item,
            key: item.synapse_report_id,
            id: item.synapse_report_id,
            subscription_date: selectedReport.subscription_date,
            expiry_date: selectedReport.expiry_date,
          };
        }
        return {
          ...item,
          key: item.synapse_report_id,
          id: item.synapse_report_id,
          subscription_date: dayjs(),
          expiry_date: dayjs(),
        };
      });
      setRowData(newData);
      setFullData(newData);
      //Set pagination data
      // const { page, limit, report_count, totalPages } = reportData?.meta;
      // setPage(page - 1);
      // setRowsPerPage(limit);
      // setTotalPages(totalPages);
      // setTotalReport(report_count);
    }
  }, [reportData, selectedReportData]);
  const steps = ["Edit Data", "Review the Data"];

  const handleStep = (step) => () => {
    if (step === 1) {
      if (selected.length === 0) {
        toast.error("Please select at least one report");
        return;
      }
      setActiveStep(1);
    } else {
      setActiveStep(step);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Subscription Start",
      dataIndex: "subscription_date",
      render: (_, record, index) => {
        const isEnabled = selectedRowData.some((item) => item.id === record.id);
        const latestDate = selectedRowData.find(
          (item) => item.id === record.id
        )?.subscription_date;
        return (
          <DatePicker
            minDate={dayjs()}
            value={latestDate}
            disabled={!isEnabled}
            onChange={(date) => {
              handleDateChange(date, index, "subscription_date", record.id);
            }}
          />
        );
      },
    },
    {
      title: "Subscription End",
      dataIndex: "expiry_date",
      render: (_, record, index) => {
        const isEnabled = selectedRowData.some((item) => item.id === record.id);
        const latestDate = selectedRowData.find(
          (item) => item.id === record.id
        )?.expiry_date;
        return (
          <DatePicker
            // minDate={dayjs().add(1, "day")}
            value={latestDate}
            disabled={!isEnabled}
            onChange={(date) => {
              handleDateChange(date, index, "expiry_date", record.id);
            }}
          />
        );
      },
    },
    {
      title: "Forecast Year",
      dataIndex: "forecast_year",
    },
  ];
  const rowSelection = {
    selected,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      const selectedRows = newSelectedRowKeys.map((key) => {
        const row = rowData.find((row) => row.id === key);
        const subscription_date =
          form.getFieldValue("subscription_date") ?? row.subscription_date;
        const expiry_date =
          form.getFieldValue("expiry_date") ?? row.expiry_date;
        return {
          ...row,
          subscription_date: subscription_date ?? dayjs(),
          expiry_date: expiry_date ?? dayjs(),
        };
      });

      //Compare selectedRows with selectedReportData which is already in selectedReportData and set the subscription and expiry date
      selectedRows.forEach((row) => {
        const selectedReport = selectedReportData.find(
          (selected) => selected.synapse_report_id === row.id
        );
        if (selectedReport) {
          row.subscription_date = selectedReport.subscription_date;
          row.expiry_date = selectedReport.expiry_date;
        }
      });

      setSelected(newSelectedRowKeys);
      setSelectedRowData(selectedRows);

      if (query?.length > 1) {
        dispatch(
          updateSelectedReport({
            data: selectedRows,
            isSearch: true,
            searchSource: filteredData,
            exitingStateData: selectedReportData,
          })
        );
      } else {
        dispatch(updateSelectedReport({ data: selectedRows }));
      }
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      const updatedRowData = [...rowData];
      const selectedIndex = updatedRowData.findIndex(
        (row) => row.id === record.id
      );
      const subscription_date = form.getFieldValue("subscription_date");
      const expiry_date = form.getFieldValue("expiry_date");

      const columnSubscriptionDate = rowData?.[selectedIndex].subscription_date;
      const columnExpiryDate = rowData?.[selectedIndex]?.expiry_date;
      if (selected) {
        updatedRowData[selectedIndex] = {
          ...record,
          subscription_date:
            columnSubscriptionDate ?? subscription_date?.isValid()
              ? subscription_date
              : dayjs(),
          expiry_date:
            columnExpiryDate ?? expiry_date?.isValid() ? expiry_date : dayjs(),
        };
      } else {
        updatedRowData[selectedIndex] = record;
      }

      //Modify selectedCloneRowData
      const modifedSelectedRowData = selectedReportData.map((item) => {
        return item.id === record.id
          ? {
              ...item,
              subscription_date: item?.subscription_date?.isValid
                ? item?.subscription_date
                : subscription_date?.isValid()
                ? subscription_date
                : dayjs(),
              expiry_date: item?.expiry_date?.isValid
                ? item?.expiry_date
                : expiry_date?.isValid()
                ? expiry_date
                : dayjs(),
            }
          : item;
      });
      setRowData(updatedRowData);

      setSelectedRowData(modifedSelectedRowData);

      if (query?.length > 1) {
        dispatch(
          updateSelectedReport({
            data: modifedSelectedRowData,
            isSearch: true,
            searchSource: filteredData,
            exitingStateData: selectedReportData,
          })
        );
      } else {
        dispatch(updateSelectedReport({ data: modifedSelectedRowData }));
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const updatedRows = rowData.map((row) => {
          const subscription_date = form.getFieldValue("subscription_date");
          const expiry_date = form.getFieldValue("expiry_date");
          return {
            ...row,
            subscription_date: subscription_date?.isValid()
              ? subscription_date
              : dayjs(),
            expiry_date: expiry_date?.isValid() ? expiry_date : dayjs(),
          };
        });
        setRowData(updatedRows);
      }
    },
  };

  const onFinish = (values) => {
    const computeData = {
      email: userEmail,
      data: {
        hubs: [],
        flash: [],
        library: {
          access_type: values.hubAccessType,
          reports_arr: [],
          expiry_date: null,
          subscription_date: null,
        },
      },
    };

    if (hubAccessType === "trial" || hubAccessType === "full") {
      computeData.data.library.expiry_date = dayjs(values.expiry_date).format(
        "MM/DD/YYYY"
      );
    } else if (hubAccessType === "full") {
      computeData.data.library.subscription_date = dayjs(
        values.subscription_date
      ).format("MM/DD/YYYY");
    } else if (hubAccessType === "limited") {
      computeData.data.library.reports_arr = selectedReportData.map((item) => {
        return {
          synapse_report_id: item.id,
          title: item.title,
          subscription_date: dayjs(item.subscription_date).format("MM/DD/YYYY"),
          expiry_date: dayjs(item.expiry_date).format("MM/DD/YYYY"),
        };
      });
    }

    updateUser(computeData)
      .then((res) => {
        if (res?.error?.data) {
          toast.error(res?.error?.data ?? "Failed to submit");
          return;
        }
        toast.success("Report assigned successfully");
        form.resetFields();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const [subCategories, setSubCategories] = useState([newReportAssignmentData]); // Start with root categories

  // useEffect(() => {
  //   const createFlatIds = selectedIds[selectedIds.length - 1];
  //   // console.log("flat ids", createFlatIds);
  //   if (createFlatIds.length > 0) {
  //     const filterData = findMatches(createFlatIds, filteredData);
  //     setFilteredData(filterData);
  //   }
  // }, [selectedIds]);

  useEffect(() => {
    // console.log("selectedIds:", selectedIds[selectedIds.length - 1]);
    const removeEmpty = selectedIds?.filter((item) => item.length > 0);
    const createFlatIds = removeEmpty?.[removeEmpty.length - 1] ?? [];
    // console.log("flat ids", createFlatIds);
    if (createFlatIds.length === 0) {
      setFilteredData(query?.length > 1 ? filteredData : rowData);
    } else {
      const filterData = findMatches(
        createFlatIds,
        query?.length > 1 ? filteredData : rowData
      );
      setFilteredData(filterData);
    }
  }, [rowData, selectedIds]);

  const resetSelection = () => {
    setSelected([]);
    setSelectedRowData([]);
    dispatch(resetAll());
    setSelectedIds([[]]); // Reset selected IDs
    setSubCategories([newReportAssignmentData]); // Reset to initial categories
    form.resetFields();
  };
  const handleSearch = (event) => {
    setQuery(event.target.value);
  };

  const handleCategoryChange = (value, level) => {
    const newSelectedIds = [...selectedIds];
    newSelectedIds[level] = value;
    setSelectedIds(newSelectedIds);

    // Determine sub-categories based on multiple selections
    let newSubCategories = subCategories.slice(0, level + 1); // Keep subcategories up to the current level
    let currentCategories = newReportAssignmentData;

    for (let i = 0; i <= level; i++) {
      const selectedAtLevel = newSelectedIds[i];
      if (selectedAtLevel.length === 0) {
        newSubCategories = newSubCategories.slice(0, i); // Clear subsequent levels if no selection
        break;
      }

      let combinedSubCategories = [];
      selectedAtLevel.forEach((selectedId) => {
        const selectedCategory = currentCategories.find(
          (cat) => cat.id === selectedId
        );
        if (selectedCategory && selectedCategory.sub_categories) {
          combinedSubCategories = [
            ...combinedSubCategories,
            ...selectedCategory.sub_categories,
          ];
        }
      });

      currentCategories = combinedSubCategories;
      if (combinedSubCategories.length > 0) {
        newSubCategories[i + 1] = combinedSubCategories;
      }
    }
    if (newSubCategories.length === 0) {
      setSubCategories([newReportAssignmentData]);
      setSelectedIds([[]]);
    } else {
      setSubCategories(newSubCategories);
    }
  };
  const renderDropdowns = () => {
    return subCategories.map((currentCategories, index) => {
      const sortedCurrentCategories = _.sortBy(currentCategories, "title");
      return (
        <Select
          key={index}
          mode="multiple"
          style={{ width: 300, marginBottom: 16, marginRight: "1rem" }}
          placeholder={`Select ${index === 0 ? "Category" : "Sub-category"}`}
          onChange={(value) => handleCategoryChange(value, index)}
          value={selectedIds[index]}
        >
          {sortedCurrentCategories.map((category) => (
            <Option key={category.id} value={category.id}>
              {category.title}
            </Option>
          ))}
        </Select>
      );
    });
  };

  return (
    <Form onFinish={onFinish} layout="vertical" form={form}>
      <Box sx={{ marginTop: "0.5rem" }}>
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              label="Access Type"
              name="hubAccessType"
              rules={[{ required: true, message: "This field is required" }]}
              style={{
                width: "50%",
              }}
            >
              <Select
                placeholder="Select a hub access type"
                onChange={(value) => {
                  dispatch(
                    updateLibraryFormDetails({ key: "hubAccessType", value })
                  );
                }}
              >
                {accessTypeList?.map((hub) => (
                  <Option
                    key={hub.id}
                    value={hub.id}
                    disabled={hub.name === "Full" && (customReports || selectedUserHasSemiAccess)}
                  >
                    {hub.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              onClick={resetSelection}
              style={{ marginLeft: "10px", marginTop: "0.4rem" }}
            >
              Reset All
            </Button>
          </div>
          {hubAccessType === "limited" && (
            <Row gutter={16} align="middle">
              <Col span={16}>
                <Form.Item
                  label={
                    <span style={{ fontSize: "16px", fontWeight: "600" }}>
                      Category:
                    </span>
                  }
                  name="category"
                >
                  {renderDropdowns()}
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            {hubAccessType !== "trial" && (
              <Col span={4}>
                <Form.Item
                  label="Subscription Start"
                  name="subscription_date"
                  rules={[
                    {
                      required: hubAccessType === "full" ? true : false,
                      message: "Subscription Start is required",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(date) => {
                      const updatedSelectedRowData = selectedRowData.map(
                        (item) => {
                          return {
                            ...item,
                            subscription_date: date,
                          };
                        }
                      );
                      setSelectedRowData(updatedSelectedRowData);
                      if (query?.length > 1) {
                        dispatch(
                          updateSelectedReport({
                            data: updatedSelectedRowData,
                            isSearch: true,
                            searchSource: filteredData,
                            exitingStateData: selectedReportData,
                          })
                        );
                      } else {
                        dispatch(
                          updateSelectedReport({ data: updatedSelectedRowData })
                        );
                      }
                      dispatch(
                        updateLibraryFormDetails({
                          key: "subscription_date",
                          value: date,
                        })
                      );
                    }}
                    format="YYYY/MM/DD"
                    minDate={dayjs()}
                  />
                </Form.Item>
              </Col>
            )}

            {(hubAccessType === "full" || hubAccessType === "limited") && (
              <Col span={4}>
                <Form.Item
                  label="Subscription End"
                  name="expiry_date"
                  rules={[
                    {
                      required: hubAccessType === "full" ? true : false,
                      message: "Subscription End is required",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(date) => {
                      const updatedSelectedRowData = selectedRowData.map(
                        (item) => {
                          return {
                            ...item,
                            expiry_date: date,
                          };
                        }
                      );
                      setSelectedRowData(updatedSelectedRowData);
                      if (query?.length > 1) {
                        dispatch(
                          updateSelectedReport({
                            data: updatedSelectedRowData,
                            isSearch: true,
                            searchSource: filteredData,
                            exitingStateData: selectedReportData,
                          })
                        );
                      } else {
                        dispatch(
                          updateSelectedReport({ data: updatedSelectedRowData })
                        );
                      }
                      dispatch(
                        updateLibraryFormDetails({
                          key: "expiry_date",
                          value: date,
                        })
                      );
                    }}
                    format="YYYY/MM/DD"
                    minDate={dayjs().add(1, "day")}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Form.Item>
            {hubAccessType === "limited" ? (
              <>
                {/* <Button
                  onClick={() => {
                    if (selected.length === 0) {
                      toast.error("Please select at least one report");
                      return;
                    }
                    setActiveStep(1);
                  }}
                  type="primary"
                  style={{
                    marginBottom: "1rem",
                  }}
                >
                  Review the Data
                </Button> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Input
                    placeholder="Search Library Reports"
                    value={query}
                    onChange={handleSearch}
                    style={{
                      marginBottom: "1rem",
                      width: "20%",
                      border: "1px solid #67bfff",
                    }}
                  />
                </div>
                <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                  <p>Selected Rows: {selectedReportData?.length ?? 0}</p>
                  <Switch
                    checkedChildren="Custom Reports"
                    unCheckedChildren="All Reports"
                    style={{ marginLeft: "1rem" }}
                    defaultChecked={customReports}
                    disabled={!selectedUserHasSemiAccess}
                    onChange={(checked) => setCustomReports(checked)}
                  />
                </div>
                <Table
                  loading={reportDataLoading || mainLoader}
                  rowSelection={{
                    ...rowSelection,
                    selectedRowKeys: selected,
                  }}
                  className="custom-table-style"
                  columns={columns}
                  dataSource={filteredData}
                  // key={rowData}
                  bordered
                  pagination={{
                    total: totalReport,
                    defaultPageSize: rowsPerPage,
                    // pageSize: rowsPerPage,
                    onChange: (page, pageSize) => {
                      // trigger({
                      //   limit: pageSize,
                      //   page: page,
                      // });
                    },
                  }}
                />
              </>
            ) : null}
          </Form.Item>
        </>
      </Box>
    </Form>
  );
}

export default LibraryCreate;
