import React from 'react';
import styles from "./styles.module.css";

function Header({children}) {
  return (
    <h1 className={styles.heading}>
     {children}
    </h1>
  )
}

export default Header;