import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button } from "antd";
import { privateRoutesMeta } from "../../../routes";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const MenuButtons = () => {
  const location = useLocation();
  const { permission } = useSelector((state) => state.userPermission);
  // Function to find the parent route for the current location
  const getParentRoute = () => {
    const currentPath = location.pathname;
    const matchingParent = privateRoutesMeta.find(
      (route) => currentPath.startsWith(`/home/${route.path}`) && route.child
    );
    return matchingParent;
  };
  // Get the current parent based on the URL
  const currentParent = getParentRoute();
  const cookies = new Cookies();
  const role = cookies.get("ROLE");

  // Define styles for the buttons
  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    margin: "10px",
    border: "none",
    backgroundColor: "transparent",
    padding: "20px",
    color: "#5a5a5a",
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#189cde",
    color: "white",
  };

  // Generate child menu items if a current parent is found
  const menuItems = currentParent
    ? currentParent.child.map((child) => {
        if (
          child.key === "create-cms-user" &&
          role !== "SUPER_ADMIN" &&
          role !== "FORMATTING_MANAGER" &&
          role !== "SALES_MANAGER"
        ) {
          return null;
        }
        if (child.key === "report-assignment" && role === "SALES_EXECUTIVE") {
          return null;
        }
        if (
          child.key === "users-list" &&
          (role === "SALES_EXECUTIVE" || role === "FORMATTING_MANAGER")
        ) {
          return null;
        }
        if (child.key === "create-user" && role === "FORMATTING_MANAGER") {
          return null;
        }
        if (
          child.key === "cms-users-list" &&
          (role === "SALES_MANAGER" ||
            role === "SALES_EXECUTIVE" ||
            role === "FORMATTING_MANAGER" ||
            role === "FORMATTING_EXECUTIVE")
        ) {
          return null;
        }
        const isSelected = location.pathname.includes(child.path);
        return (
          <NavLink
            key={child.key}
            to={child.path}
            style={{ textDecoration: "none" }} // Remove underline from links
          >
            <Button style={isSelected ? selectedButtonStyle : buttonStyle}>
              {child.title}
            </Button>
          </NavLink>
        );
      })
    : null;

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {menuItems}
    </div>
  );
};

export default MenuButtons;
