import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";

import "./stylesheets/index.css";
import "./app.css";

import store from "./redux/store";

import PrivateRoutes from "./components/PrivateRoutes";
import Banner from "./components/Banner";

import PageNotFound from "./pages/PageNotFound";

import { privateRoutes, publicRoutes } from "./routes";
import Login from "./pages/LogIn";
import NewDashboard from "./pages/NewDashboard/Index";
import CreateUser from "./pages/CreateUser";
import CreateReport from "./pages/CreateReport";
import UpdateUser from "./components/UpdateUser";
import UserList from "./pages/UserList";
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";
import Flash from "./pages/CustomReports/Flash/Index";
import Hubs from "./pages/CustomReports/Hubs";
import AttachReport from "./pages/AttachReport";
import AssignReport from "./pages/AssignReport";
import Uploads from "./pages/Upload/Upload";
import UpdateReport from "./pages/UpdateReport";
import ReportAssignment from "./pages/ReportAssignment";
import UploadFiles from "./pages/ContentManagement/UploadFiles";
import BulkUpload from "./pages/ContentManagement/BulkUpload";
import viewLoginHistory from "./pages/Settings/ViewLoginHistory";
import ViewLoginHistory from "./pages/Settings/ViewLoginHistory";
import NewCreateUser from "./pages/UserManagement/CreateUser";
import NewUserList from "./pages/UserManagement/UserList";
import CMSUser from "./pages/UserManagement/CMSUser";
import CMSUserList from "./pages/UserManagement/CMSUserList";
import { useJwt } from "react-jwt";
import Cookies from "universal-cookie";
import PowerBI from "./pages/PowerBI";
import EditPowerBI from "./pages/PowerBI/EditPowerBI";

function App() {
  const cookies = new Cookies();
  const role = cookies.get("ROLE");

  const accessRoutes = {
    SUPER_ADMIN: [
      {
        path: "user-management",
        title: "User Management",
        children: [
          {
            path: "create-user",
            element: (
              <PrivateRoutes>
                <NewCreateUser />
              </PrivateRoutes>
            ),
          },
          {
            path: "update-user",
            element: (
              <PrivateRoutes>
                <UpdateUser />
              </PrivateRoutes>
            ),
          },
          {
            path: "report-assignment",
            element: (
              <PrivateRoutes>
                <AssignReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "users-list",
            element: (
              <PrivateRoutes>
                <NewUserList />
              </PrivateRoutes>
            ),
          },
          {
            path: "create-cms-user",
            element: (
              <PrivateRoutes>
                <CMSUser />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "content-management",
        title: "Content Management",
        children: [
          {
            path: "view-and-edit-reports",
            element: (
              <PrivateRoutes>
                <UpdateReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "create-report",
            element: (
              <PrivateRoutes>
                <CreateReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "upload-files-1",
            element: (
              <PrivateRoutes>
                <UploadFiles />
              </PrivateRoutes>
            ),
          },
          {
            path: "bulk-upload",
            element: (
              <PrivateRoutes>
                <BulkUpload />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "custom-reports-management",
        title: "Custom Report Management",
        children: [
          {
            path: "upload-files-2",
            element: (
              <PrivateRoutes>
                <Uploads />
              </PrivateRoutes>
            ),
          },
          {
            path: "attach-reports",
            element: (
              <PrivateRoutes>
                <AttachReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "assign-report",
            element: (
              <PrivateRoutes>
                <AssignReport />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "settings",
        title: "Settings",
        children: [
          {
            path: "view-login-history",
            element: (
              <PrivateRoutes>
                <ViewLoginHistory />
              </PrivateRoutes>
            ),
          },
          {
            path: "change-password",
            element: (
              <PrivateRoutes>
                <Settings />
              </PrivateRoutes>
            ),
          },
        ],
      },
    ],
    SALES_MANAGER: [
      {
        path: "user-management",
        title: "User Management",
        children: [
          {
            path: "create-user",
            element: (
              <PrivateRoutes>
                <NewCreateUser />
              </PrivateRoutes>
            ),
          },
          {
            path: "update-user",
            element: (
              <PrivateRoutes>
                <UpdateUser />
              </PrivateRoutes>
            ),
          },
          {
            path: "report-assignment",
            element: (
              <PrivateRoutes>
                <AssignReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "users-list",
            element: (
              <PrivateRoutes>
                <NewUserList />
              </PrivateRoutes>
            ),
          },
          {
            path: "create-cms-user",
            element: (
              <PrivateRoutes>
                <CMSUser />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "settings",
        title: "Settings",
        children: [
          {
            path: "view-login-history",
            element: (
              <PrivateRoutes>
                <ViewLoginHistory />
              </PrivateRoutes>
            ),
          },
          {
            path: "change-password",
            element: (
              <PrivateRoutes>
                <Settings />
              </PrivateRoutes>
            ),
          },
        ],
      },
    ],
    SALES_EXECUTIVE: [
      {
        path: "user-management",
        title: "User Management",
        children: [
          {
            path: "create-user",
            element: (
              <PrivateRoutes>
                <NewCreateUser />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "settings",
        title: "Settings",
        children: [
          {
            path: "view-login-history",
            element: (
              <PrivateRoutes>
                <ViewLoginHistory />
              </PrivateRoutes>
            ),
          },
          {
            path: "change-password",
            element: (
              <PrivateRoutes>
                <Settings />
              </PrivateRoutes>
            ),
          },
        ],
      },
    ],
    FORMATTING_MANAGER: [
      {
        path: "content-management",
        title: "Content Management",
        children: [
          {
            path: "view-and-edit-reports",
            element: (
              <PrivateRoutes>
                <UpdateReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "create-report",
            element: (
              <PrivateRoutes>
                <CreateReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "upload-files-1",
            element: (
              <PrivateRoutes>
                <UploadFiles />
              </PrivateRoutes>
            ),
          },
          {
            path: "bulk-upload",
            element: (
              <PrivateRoutes>
                <BulkUpload />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "custom-reports-management",
        title: "Custom Report Management",
        children: [
          {
            path: "upload-files-2",
            element: (
              <PrivateRoutes>
                <Uploads />
              </PrivateRoutes>
            ),
          },
          {
            path: "attach-reports",
            element: (
              <PrivateRoutes>
                <AttachReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "assign-report",
            element: (
              <PrivateRoutes>
                <AssignReport />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "settings",
        title: "Settings",
        children: [
          {
            path: "view-login-history",
            element: (
              <PrivateRoutes>
                <ViewLoginHistory />
              </PrivateRoutes>
            ),
          },
          {
            path: "change-password",
            element: (
              <PrivateRoutes>
                <Settings />
              </PrivateRoutes>
            ),
          },
        ],
      },
    ],
    FORMATTING_EXECUTIVE: [
      {
        path: "content-management",
        title: "Content Management",
        children: [
          {
            path: "view-and-edit-reports",
            element: (
              <PrivateRoutes>
                <UpdateReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "create-report",
            element: (
              <PrivateRoutes>
                <CreateReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "upload-files-1",
            element: (
              <PrivateRoutes>
                <UploadFiles />
              </PrivateRoutes>
            ),
          },
          {
            path: "bulk-upload",
            element: (
              <PrivateRoutes>
                <BulkUpload />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "custom-reports-management",
        title: "Custom Report Management",
        children: [
          {
            path: "upload-files-2",
            element: (
              <PrivateRoutes>
                <Uploads />
              </PrivateRoutes>
            ),
          },
          {
            path: "attach-reports",
            element: (
              <PrivateRoutes>
                <AttachReport />
              </PrivateRoutes>
            ),
          },
          {
            path: "assign-report",
            element: (
              <PrivateRoutes>
                <AssignReport />
              </PrivateRoutes>
            ),
          },
        ],
      },
      {
        path: "settings",
        title: "Settings",
        children: [
          {
            path: "view-login-history",
            element: (
              <PrivateRoutes>
                <ViewLoginHistory />
              </PrivateRoutes>
            ),
          },
          {
            path: "change-password",
            element: (
              <PrivateRoutes>
                <Settings />
              </PrivateRoutes>
            ),
          },
        ],
      },
    ],
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Banner />,
      children: [
        {
          path: "",
          element: <Login />,
        },
      ],
    },
    {
      path: "/not-authorized",
      element: <PageNotFound />,
    },
    {
      path: "/home",
      title: "DashboardOutlined",
      element: (
        <PrivateRoutes path="home">
          <NewDashboard />
        </PrivateRoutes>
      ),
      children: [
        {
          path: "dashboard",
          index: true,
          element: (
            <PrivateRoutes path="dashboard">
              <Dashboard />
            </PrivateRoutes>
          ),
        },
        {
          path: "user-management",
          title: "User Management",
          children: [
            {
              path: "create-user",
              element: (
                <PrivateRoutes path="create-user">
                  <NewCreateUser />
                </PrivateRoutes>
              ),
            },
            {
              path: "update-user",
              element: (
                <PrivateRoutes path="update-user">
                  <UpdateUser />
                </PrivateRoutes>
              ),
            },
            {
              path: "report-assignment",
              element: (
                <PrivateRoutes path="report-assignment">
                  <AssignReport />
                </PrivateRoutes>
              ),
            },
            {
              path: "users-list",
              element: (
                <PrivateRoutes path="users-list">
                  <NewUserList />
                </PrivateRoutes>
              ),
            },
            {
              path: "create-cms-user",
              element: (
                <PrivateRoutes path="create-cms-user">
                  <CMSUser />
                </PrivateRoutes>
              ),
            },
            {
              path: "cms-users-list",
              element: (
                <PrivateRoutes path="cms-users-list">
                  <CMSUserList />
                </PrivateRoutes>
              ),
            },
          ],
        },
        {
          path: "content-management",
          title: "Content Management",
          children: [
            {
              path: "view-and-edit-reports",
              element: (
                <PrivateRoutes path="view-and-edit-reports">
                  <UpdateReport />
                </PrivateRoutes>
              ),
            },
            {
              path: "create-report",
              element: (
                <PrivateRoutes path="create-report">
                  <CreateReport />
                </PrivateRoutes>
              ),
            },
            {
              path: "upload-files-1",
              element: (
                <PrivateRoutes path="upload-files-1">
                  <UploadFiles />
                </PrivateRoutes>
              ),
            },
            {
              path: "bulk-upload",
              element: (
                <PrivateRoutes path="bulk-upload">
                  <BulkUpload />
                </PrivateRoutes>
              ),
            },
          ],
        },
        {
          path: "custom-reports-management",
          title: "Custom Report Management",
          children: [
            {
              path: "upload-files-2",
              element: (
                <PrivateRoutes path="upload-files-2">
                  <Uploads />
                </PrivateRoutes>
              ),
            },
            {
              path: "attach-reports",
              element: (
                <PrivateRoutes path="attach-reports">
                  <AttachReport />
                </PrivateRoutes>
              ),
            },
            {
              path: "assign-report",
              element: (
                <PrivateRoutes path="assign-report">
                  <AssignReport />
                </PrivateRoutes>
              ),
            },
          ],
        },
        {
          path: "settings",
          title: "Settings",
          children: [
            {
              path: "view-login-history",
              element: (
                <PrivateRoutes path="view-login-history">
                  <ViewLoginHistory />
                </PrivateRoutes>
              ),
            },
            {
              path: "change-password",
              element: (
                <PrivateRoutes path="change-password">
                  <Settings />
                </PrivateRoutes>
              ),
            },
          ],
        },
        // {
        //   path: "power-bi-integration",
        //   title: "BI Integration",
        //   element: (
        //     <PrivateRoutes path="power-bi-integration">
        //       <PowerBI />
        //     </PrivateRoutes>
        //   ),
        // },
        {
          path: "power-bi-integration",
          title: "BI Integration",
          children: [
            {
              path: "add-dashboards",
              element: (
                <PrivateRoutes path="add-dashboards">
                  <PowerBI />
                </PrivateRoutes>
              ),
            },
            {
              path: "edit-dashboards",
              element: (
                <PrivateRoutes path="edit-dashboards">
                  <EditPowerBI />
                </PrivateRoutes>
              ),
            },
          ],
        },
        // ...(accessRoutesState?.[role] ?? []),
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);

  let token = cookies.get("TOKEN");

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
