import React, { useEffect, useState, useCallback } from "react";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  AutoComplete,
  DatePicker,
  Input,
  InputNumber,
  List,
  Select,
  Space,
  Spin,
} from "antd";
import { Button, Checkbox } from "antd";
import Cookies from "universal-cookie";
import API from "../../utils/api";
import filteredArr from "../../utils/countryConfig";
import arrFile from "../../utils/categoriesConfig";
import axios from "axios";
import { SearchOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Category } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  useGetRelationShipManagerEmailQuery,
  useGetUserApiQuery,
} from "../../redux/api/createUserApi";
import moment from "moment/moment";
import dayjs from "dayjs";
// import debounce from 'lodash/debounce';

const years = [
  { value: 2016, label: 2016 },
  { value: 2017, label: 2017 },
  { value: 2018, label: 2018 },
  { value: 2019, label: 2019 },
  { value: 2020, label: 2020 },
  { value: 2021, label: 2021 },
  { value: 2022, label: 2022 },
  { value: 2023, label: 2023 },
  { value: 2024, label: 2024 },
  { value: 2025, label: 2025 },
  { value: 2026, label: 2026 },
  { value: 2027, label: 2027 },
  { value: 2028, label: 2028 },
  { value: 2029, label: 2029 },
  { value: 2030, label: 2030 },
];

let regionArr = Object.keys(filteredArr)?.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));
let categoriesArr = Object.values(arrFile)?.map((value) => ({
  label: `${value.title}`,
  value: `${value.title}`,
}));
let NDA_Options = [
  {
    value: "NDA",
    label: "NDA",
  },
  {
    value: "Client Name Mentioned",
    label: "Client Name Mentioned",
  },
  {
    value: "Exclusivity",
    label: "Exclusivity",
  },
  {
    value: "No",
    label: "No",
  },
];
const fileTypeOptions = [
  {
    value: "Update",
    label: "Update",
  },
  {
    value: "New",
    label: "New",
  },
];

const websiteTitleOptions = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const sharedRegionProps = {
  mode: "multiple",
  style: {
    width: "100%",
  },
  options: regionArr?.sort((a, b) => a.label.localeCompare(b.label)),
  placeholder: "Select Item...",
  maxTagCount: "responsive",
};

const typeOptions = [
  {
    label: "Report & ME",
    value: "Report & ME",
  },
  {
    label: "Custom Report & ME",
    value: "Custom Report & ME",
  },
  {
    label: "Report",
    value: "Report",
  },
];

function transformData(data) {
  return data?.map((item) => {
    const transformedItem = {
      value: item.id,
      label: item.title,
    };
    if (item.sub_categories) {
      transformedItem.children = transformData(item.sub_categories);
    }
    return transformedItem;
  });
}

function CreateReport({ hide, setHide, handleRefetch }) {
  const [updateData, setUpdateData] = useState([]);
  const [title, setTitle] = useState("");
  const [reportId, setReportId] = useState("");
  const [shortTitle, setShortTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [baseYear, setBaseYear] = useState("2024");
  const [forecastYearFrom, setForecastYearFrom] = useState("2016");
  const [forecastYearTo, setForecastYearTo] = useState("");
  const [studyPeriodFrom, setStudyPeriodFrom] = useState("2016");
  const [studyPeriodTo, setStudyPeriodTo] = useState("2024");
  const [deepCategoryInitData, setDeepCategoryInitData] = useState([]);
  const [deepCategoryData, setDeepCategoryData] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [parentOptions, setParentOptions] = useState([]);
  const [parentSelectedOption, setParentSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [region, setRegion] = useState([]);
  const [country, setCountry] = useState([]);
  const [cleanType, setCleanType] = useState("report");
  const [version, setVersion] = useState("");
  const [countryArr, setCountryArr] = useState([]);
  const [subCategoryArr, setSubCategoryArr] = useState([]);
  const [deepCatArry, setDeepCatArray] = useState([]);
  const [ndaValue, setNDAValue] = useState("No");
  const [publishReportTo, setPublishReportTo] = useState("library");
  const [visibleToAll, setVisibleToAll] = useState(true);
  const [additionalComments, setAdditionalComments] = useState("");
  const [freshSaleId, setFreshSaleId] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [owner, setOwner] = useState("");
  const [dispatchedClientId, setDispatchedClientId] = useState("");
  const [fileType, setFileType] = useState("");
  const [pageNumber, setPageNumber] = useState("");
  const [websiteTitleStatus, setWebsiteTitleStatus] = useState("");
  const [batch, setBatch] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [publishReport, setPublishReport] = useState(false);
  const [customReport, setCustomReport] = useState(false);
  const [myRaAiAvailable, setMyRaAiAvailable] = useState(true);
  const [type, setType] = useState("");
  const [pdfUrls, setPdfUrls] = useState([]);
  const [pptUrls, setPptUrls] = useState([]);
  const [meUrls, setMeUrls] = useState([]);
  const [excelUrls, setExcelUrls] = useState([]);
  const [blurPdfUrls, setBlurPdfUrls] = useState([]);
  const [searchType, setSearchType] = useState("title");

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);

  const [errors, setErrors] = useState({});

  const cookies = new Cookies();

  let token = cookies.get("TOKEN");
  const { Base_URL, addReport, updateReport, categoryTree } = API;

  let config = {
    method: "post",
    headers: { authorization: `Bearer ${token}` },
    maxBodyLength: Infinity,
    url: `${Base_URL}${addReport}`,
  };

  const { data: userData, error, isLoading, isFetching } = useGetUserApiQuery();
  const {
    data: relationshipManagerEmailData,
    isLoading: relationshipManagerEmailLoading,
    isError: relationshipManagerEmailError,
  } = useGetRelationShipManagerEmailQuery();
  //From userData we only get two fields name and email
  const [modifiedUserData, setModifiedUserData] = useState([]);
  const [
    modifiedRelationshipManagerEmailData,
    setModifiedRelationshipManagerEmailData,
  ] = useState([]);
  useEffect(() => {
    if (userData?.data?.length > 0) {
      let newResult = userData?.data?.map((user) => {
        return {
          value: user._id,
          label: user.name,
        };
      });
      setModifiedUserData(newResult);
    }
  }, [userData]);

  useEffect(() => {
    if (relationshipManagerEmailData?.data?.length > 0) {
      let newResult = relationshipManagerEmailData?.data?.map((user) => {
        return {
          value: user.name,
          label: user.name,
        };
      });
      setModifiedRelationshipManagerEmailData(newResult);
    }
  }, [relationshipManagerEmailData]);

  const handleChange = (value, count, check) => {
    if (check) {
      setDeepCatArray((prev) => {
        // Ensure prev is an array before spreading. If not, default to an empty array.
        const newVal = Array.isArray(prev) ? [...prev] : [];
        newVal[count] = value;
        return newVal;
      });
    } else {
      setDeepCatArray(deepCatArry.filter((item, i) => i !== count));
    }
  };

  const handleRemove = (value) => {
    const removeNode = (nodes, value) => {
      return nodes.filter((node) => {
        if (node.value === value) {
          return false;
        }
        if (node.children) {
          node.children = removeNode(node.children, value);
        }
        return true;
      });
    };

    setDeepCategoryData(removeNode(deepCategoryData, value));
  };

  function convertToSlug(text) {
    return text.toLowerCase().replace(/\s+/g, "-");
  }

  const fetchOptions = async (value, searchTypeParam = "title") => {
    setLoading(true);
    try {
      // Perform API request to fetch options based on value
      const response = await axios.get(
        `${Base_URL}search?q=${value}&search_by=${searchTypeParam}`
      );
      const newData = response.data;
      setParentData(newData);
      const options = newData?.map((result) => ({
        value: result.title,
        label: result.title,
      }));
      setParentOptions(options);
    } catch (error) {
      console.error("Error fetching options:", error);
      // Handle error if necessary
    } finally {
      setLoading(false); // Set loading to false when request completes
    }
  };

  const fetchCategories = async () => {
    // setLoading(true);
    try {
      // Perform API request to fetch options based on value
      const response = await axios.get(`${Base_URL}${categoryTree}`);
      const newData = response.data.data;
      setDeepCategoryData(transformData(newData));
      setDeepCategoryInitData(transformData(newData));
    } catch (error) {
      console.error("Error fetching options:", error);
      // Handle error if necessary
    } finally {
      //   setLoading(false); // Set loading to false when request completes
    }
  };

  // Debounce the fetchOptions function to limit API requests

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  // Generate options for the month select input
  const generateOptions = (sysdate) => {
    const options = [];
    const start = new Date(2019, 3); // Start from April 2019
    let currentDate = new Date();
    let end = new Date(currentDate.getFullYear() + 1, 11, 31);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let date = start; date <= end; date.setMonth(date.getMonth() + 1)) {
      const option = {
        value: `${monthNames[date.getMonth()]} ${date.getFullYear()}`,
        label: `${monthNames[date.getMonth()]} ${date.getFullYear()}`,
      };
      options.push(option);
    }

    return options;
  };

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    if (searchText.trim().length > 1) {
      debouncedFetchOptions(searchText, searchType);
    } else {
      setParentOptions([]);
    }
  }, [searchText, debouncedFetchOptions, searchType]);

  const handleSearch = (value, label) => {
    setSearchText(value);
  };

  const onSelect = (value) => {
    // setHide(true)
    setParentOptions([]);
    setSearchText(value);
    const selectedOne = parentData?.filter((item) => item.title === value);
    setParentSelectedOption(selectedOne[0]);
  };

  const handleDeletePdfUrl = (index) => {
    const updatedPdfUrls = pdfUrls.filter((_, i) => i !== index);
    setPdfUrls(updatedPdfUrls);
  };

  const handleDeletePptUrl = (index) => {
    const updatedPptUrls = pptUrls.filter((_, i) => i !== index);
    setPptUrls(updatedPptUrls);
  };

  const handleDeleteExcelUrl = (index) => {
    const updatedExcelUrls = excelUrls.filter((_, i) => i !== index);
    setExcelUrls(updatedExcelUrls);
  };

  const handleDeleteBlurPdfUrl = (index) => {
    const updatedBlurPdfUrls = blurPdfUrls.filter((_, i) => i !== index);
    setBlurPdfUrls(updatedBlurPdfUrls);
  };

  useEffect(() => {
    if (hide) {
      const getData = localStorage.getItem("FOR_UPDATE");
      // const dataArr = JSON.parse(getData);
      const dataArr = JSON.parse(getData ?? {});
      const data = dataArr[0];
      setUpdateData(data);
      setTitle(data && data.title);
      setShortTitle(data && data.short_title);
      setSubTitle(data && data.sub_title);
      setSlug(data && data.slug);
      setBaseYear(data.base_year);
      setStudyPeriodFrom(
        data && data.study_period && data.study_period.split("-")[0]
      );
      setStudyPeriodTo(
        data && data.study_period && data.study_period.split("-")[1]
      );
      setForecastYearFrom(
        data && data.forecast_year && data.forecast_year.includes("-")
          ? data.forecast_year.split("-")[0]
          : "2016"
      );
      setForecastYearTo(
        data && data?.forecast_year?.includes("-")
          ? data?.forecast_year?.split?.("-")?.[1]
          : "2028"
      );
      setDeepCatArray(data && data.category_ids.length && data.category_ids);
      setRegion(
        data && data?.regions_covered?.length > 1 ? data.regions_covered : []
      );
      setCountry(data && data?.countries?.length > 1 ? data.countries : []);
      setCleanType(data && data.clean_type);
      setVersion(data && data.version);
      setActive(data && data.active);
      setPublishReport(data && data?.report_publish);
      setCustomReport(data && data?.custom_report);
      setReportId(data && data.synapse_report_id);
      setParentSelectedOption(data && data?.parent_rd ? data.parent_rd : null);
      setSearchText(data && data.parent_rd ? data.parent_rd.title : "");
      setNDAValue(data && data.nda_status ? data.nda_status : "");
      setAdditionalComments(
        data && data.additional_comments ? data.additional_comments : ""
      );
      setFreshSaleId(data && data.freshsale_id ? data.freshsale_id : "");
      setSalesPerson(data && data.sales_person ? data.sales_person : "");
      setOwner(data && data.owner ? data.owner : "");
      setDispatchedClientId(data && data.dispatched_client_id);
      setFileType(data && data.file_type);
      setPageNumber(data && data.page_number);
      setWebsiteTitleStatus(data && data.website_title_status);
      setBatch(data && data.batch);
      setPublishDate(data && data.published ? moment(data.published) : null);

      setType(data && data.type);
      setPdfUrls(data && data.pdf_url);
      setPptUrls(data && data.ppt_url);
      setExcelUrls(data && data.excel_url);
      setBlurPdfUrls(data && data.blur_pdf_url);
      setMeUrls(data && data.me_url);

      setPublishReportTo(data?.report_publish_to || "library");
      setVisibleToAll(data?.visible_to_all);
      setMyRaAiAvailable(data?.myra_chat_available);

    }
  }, []);

  function isValidSequence(data, sequence) {
    let currentLevel = data;

    for (let i = 0; i < sequence.length; i++) {
      const value = sequence[i];
      const foundItem = currentLevel.find((item) => item.value === value);

      if (!foundItem) {
        return false;
      }

      currentLevel = foundItem.children || [];
    }

    return true;
  }

  useEffect(() => {
    const defaultRegion =
      Array.isArray(region) && region.length > 0 ? region : ["Global"];

    if (defaultRegion.length > 0) {
      // Remove null, undefined, and empty string values from the array
      const newDefaultRegion = defaultRegion.filter(
        (item) => item != null && item !== ""
      );

      if (filteredArr) {
        const newCountryArr = newDefaultRegion.flatMap((region) => {
          const regionData = filteredArr[region];
          return regionData
            ? Object.values(regionData).map((value) => ({
                label: String(value),
                value: String(value),
              }))
            : [];
        });

        setCountryArr(newCountryArr);
      }
    }
  }, [region, filteredArr]);

  const sharedCountryProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    // options: countryArr?.sort((a, b) => a.label.localeCompare(b.label)),
    options: countryArr,
    placeholder: "Select Item...",
    maxTagCount: "responsive",
  };

  const handleChangeRegion = (value) => {
    let newArray = [];
    if (value.length > 0 && country.length > 0) {
      // Filter country based on selected regions
      newArray = country?.filter((countryItem) => {
        return value?.some((regionItem) =>
          filteredArr?.[regionItem]?.includes(countryItem)
        );
      });
    }
    // Update state
    setCountry(newArray);
    setRegion(value);
  };
  let regex = /^[a-zA-Z0-9 ,\-]+$/;

  const validateForm = () => {
    const errors = {};
    if (!title?.trim()) {
      errors.title = "Title is required";
    }
    if (!subTitle?.trim()) {
      errors.subTitle = "Subtitle is required";
    }
    if (!shortTitle?.trim() || regex.test(shortTitle.trim()) === false) {
      errors.shortTitle =
        "Please provide a Short Title that includes only numbers, letters, hyphens, or commas.";
    }
    if (!slug?.trim()) {
      errors.slug = "Slug is required";
    }
    if (!parentSelectedOption) {
      errors.parentSelectedOption = "Parent Report is required";
    }
    if (deepCatArry?.length === 0) {
      errors.deepCategory = "category is required";
    }
    if (!isValidSequence(deepCategoryInitData, deepCatArry)) {
      errors.deepCategory = "Categories and subcategories do not match";
    }
    // if (region?.length === 0) {
    //   errors.region = "Region is required";
    // }
    // if (country?.length === 0) {
    //   errors.country = "Country is required";
    // }
    // if (!cleanType) {
    //   errors.cleanType = "Clean Type is required";
    // }
    if (!version?.trim()) {
      errors.version = "Version is required";
    }
    if (ndaValue === "") {
      errors.nda = "NDA is required";
    }
    // if (!freshSaleId.trim()) {
    //   errors.freshSaleId = "Fresh Sale ID is required";
    // }
    if (!salesPerson.trim()) {
      errors.salesPerson = "Sales Person is required";
    }

    // if (!fileType) {
    //   errors.fileType = "File Type is required";
    // }
    if (!websiteTitleStatus) {
      errors.websiteTitleStatus = "Website Title Status is required";
    }
    if (!pageNumber) {
      errors.pageNumber = "Page Number is required";
    }
    if (!dispatchedClientId) {
      errors.dispatchedClientId = "Dispatched Client ID is required";
    }
    if (!batch) {
      errors.batch = "Batch is required";
    }
    if (!publishDate) {
      errors.publishDate = "Publish Date is required";
    }
    if (!type) {
      errors.type = "Type is required";
    }
    console.log(errors);

    return errors;
  };

  async function patchData(data) {
    let url = `${Base_URL}${updateReport}/${reportId}`;
    try {
      const res = await axios.patch(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      // alert(res.data.message)
      toast.success(res?.data?.message, {
        position: "top-right",
        autoClose: 5000,
      });
      if (res.data.message == "Report has been updated successfully") {
        //Refetch the fetchReports
        setDeepCategoryData([]);
        fetchCategories();

        setTitle("");
        setShortTitle("");
        setSubTitle("");
        setSlug("");
        setBaseYear("2024");
        setForecastYearFrom("2016");
        setForecastYearTo("2024");
        setStudyPeriodFrom("2016");
        setStudyPeriodTo("2024");
        setDeepCatArray([]);
        setRegion([]);
        setCountry([]);
        setCleanType("report");
        setVersion("");
        setSearchText("");
        setReportId("");
        setParentSelectedOption(null);
        localStorage.removeItem("HIDE_UPDATE");
        localStorage.removeItem("FOR_UPDATE");
        handleRefetch();

        setHide(false);
        setDeepCategoryData([]);
        fetchCategories();
        setSalesPerson("");
        setOwner("");
        setAdditionalComments("");
        setNDAValue("");
        setFreshSaleId("");
        setDispatchedClientId("");
        setFileType("");
        setPageNumber(0);
        setWebsiteTitleStatus("");
        setBatch("");
        setPublishDate("");
        setType("");
        setPdfUrls([]);
        setPptUrls([]);
        setExcelUrls([]);
        setBlurPdfUrls([]);
        setVisibleToAll(true);
        setMyRaAiAvailable(true);
        setPublishReportTo("library");
      }
      // return res.data;
    } catch (error) {
      console.error("Error:", error);

      // throw error; // Rethrow the error to be handled by the caller
    }
  }
  async function postData(data) {
    let url = `${Base_URL}${addReport}`;
    try {
      const res = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      // alert(res.data.message)
      toast.success(res?.data?.message, {
        position: "top-right",
        autoClose: 5000,
      });
      if (res.data.message == "Report created successfully") {
        setDeepCategoryData([]);
        fetchCategories();
        setTitle("");
        setShortTitle("");
        setSubTitle("");
        setSlug("");
        setBaseYear("2024");
        setForecastYearFrom("2016");
        setForecastYearTo("2024");
        setStudyPeriodFrom("2016");
        setStudyPeriodTo("2024");
        setDeepCatArray([]);
        setSearchText("");
        // setCategory("");
        // setSubCategory("");
        setRegion([]);
        setCountry([]);
        setParentSelectedOption(null);
        // setPublishDate("");
        setCleanType("report");
        setVersion("");
        setActive(true);
        setPublishReport(false);
        setCustomReport(false);
        setNDAValue("");
        setAdditionalComments("");
        setFreshSaleId("");
        setSalesPerson("");
        setOwner("");
        setFileType("");
        setPageNumber(0);
        setWebsiteTitleStatus("");
        setDispatchedClientId("");
        setBatch("");
        setPublishDate("");
        setType("");
        setVisibleToAll(true);
        setMyRaAiAvailable(true);
        setPublishReportTo("library");
      }
      return res.data;
    } catch (error) {
      console.error("Error:", error);

      // throw error; // Rethrow the error to be handled by the caller
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form validation
    const errors = validateForm();
    const data = {
      title: title,
      short_title: shortTitle,
      slug: slug,
      sub_title: subTitle,
      base_year: String(baseYear),
      parent_rd: {
        slug: parentSelectedOption?.slug,
        short_title: parentSelectedOption?.short_title,
        title: parentSelectedOption?.title,
        id: parentSelectedOption?.id,
      },
      forecast_year: `${
        forecastYearTo
          ? `${forecastYearFrom}-${forecastYearTo}`
          : forecastYearFrom
      }`,
      // published: publishDate,
      study_period: `${studyPeriodFrom}-${studyPeriodTo}`,
      // category: category,
      // sub_category: subCategory,
      category_ids: deepCatArry,
      regions: region,
      countries: country,
      version: version,
      price: [
        {
          license: "single",
          price: "4750",
        },
        {
          license: "team",
          price: "5250",
        },
        {
          license: "corporate",
          price: "8750",
        },
      ],
      active: active,
      report_publish: publishReport,
      custom_report: customReport,
      clean_type: cleanType,
      nda_status: ndaValue,
      additional_comments: additionalComments,
      freshsale_id: freshSaleId,
      sales_person: salesPerson,
      owner: owner,
      file_type: fileType,
      website_title_status: websiteTitleStatus,
      page_number: pageNumber,
      dispatched_client_id: dispatchedClientId,
      batch: batch,
      published_date: publishDate,
      type: type,
      report_publish_to: publishReportTo,
      visible_to_all: visibleToAll,
      myra_chat_available: myRaAiAvailable,
    };

    // Check if errors exist
    if (Object.keys(errors).length === 0) {
      // Proceed with form submission
      setErrors({});

      if (hide) {
        data["pdf_url"] = pdfUrls;
        data["ppt_url"] = pptUrls;
        data["excel_url"] = excelUrls;
        data["blur_pdf_url"] = blurPdfUrls;
        data["me_url"] = meUrls;

        patchData(data);
      } else {
        postData(data);
      }
    } else {
      // Update errors state
      setErrors(errors);
      // alert("Please make sure to fill in all the required input fields.")
      toast.warning(
        "Please make sure to fill in all the required input fields.",
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
    }
  };

  const spanErr = {
    color: "red",
    fontSize: "16px",
    lineHeight: "1.5",
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="column" gap={"20px"} mx={"20%"}>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            {" "}
            <span style={{ color: "red" }}>*</span>Title:
          </Box>
          <Box flex="80">
            <Input
              placeholder="Title"
              style={{ borderColor: errors.title ? "red" : "" }}
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            {errors.title && <span style={spanErr}>{errors.title}</span>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            {" "}
            <span style={{ color: "red" }}>*</span>Short Title:
          </Box>
          <Box flex="80">
            <Input
              placeholder="Short Title"
              style={{ borderColor: errors.shortTitle ? "red" : "" }}
              onChange={(e) => {
                const newValue = e.target.value;
                setSlug(convertToSlug(newValue));
                setShortTitle(newValue);
              }}
              value={shortTitle}
            />
            {errors.shortTitle && (
              <span style={spanErr}>{errors.shortTitle}</span>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Slug:
          </Box>
          <Box flex="80">
            <Input placeholder="slug-autofill" disabled value={slug} />
            {errors.slug && <span style={spanErr}>{errors.slug}</span>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Sub Title:
          </Box>
          <Box flex="80">
            <Input
              placeholder="Sub title"
              style={{ borderColor: errors.subTitle ? "red" : "" }}
              onChange={(e) => setSubTitle(e.target.value)}
              value={subTitle}
            />
            {errors.subTitle && <span style={spanErr}>{errors.subTitle}</span>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Base Year:
          </Box>
          <Box flex="80">
            <Input
              defaultValue="2024"
              style={{ width: "100%" }}
              onChange={(e) => setBaseYear(e.target.value)}
              value={baseYear}
              type="number"
            />
            {errors.baseYear && <span style={spanErr}>{errors.baseYear}</span>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Parent Report:
          </Box>
          <Box flex="10">
            <Select
              defaultValue="slug"
              style={{ width: 100, marginBottom: 16 }}
              value={searchType}
              onChange={(value) => setSearchType(value)}
            >
              <Option value="title">Title</Option>
              <Option value="slug">Slug</Option>
            </Select>
          </Box>
          <Box flex="70">
            <AutoComplete
              // dropdownMatchSelectWidth={252}
              style={{ width: "100%", marginBottom: 16 }}
              options={parentOptions?.map((opt) => ({
                value: opt.value,
                label: opt.label,
              }))}
              value={searchText}
              onSelect={onSelect}
              onSearch={handleSearch}
              placeholder="Search here..."
              notFoundContent={
                loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: 32,
                    }}
                  >
                    <Spin size="small" />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: 100,
                    }}
                  >
                    {/* <img style={{ width: '150px' }} src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527124.jpg?w=826&t=st=1715170623~exp=1715171223~hmac=3098a8d94ae675362bcdb1b60f64255f9bc4778c8743b3ecefda23bf37066bce" alt="No data found" /> */}
                    <p>No results found. </p>
                  </div>
                )
              }
            >
              <Input suffix={<SearchOutlined />} />
            </AutoComplete>
            {errors.parentSelectedOption && (
              <span style={spanErr}>{errors.parentSelectedOption}</span>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            Study Period:
          </Box>
          <Box flex="80">
            <Box display="flex" gap={4}>
              <Input
                style={{ width: "100%" }}
                onChange={(e) => setStudyPeriodFrom(e.target.value)}
                value={studyPeriodFrom}
                options={years}
                type="number"
              />
              <Input
                style={{ width: "100%" }}
                onChange={(e) => setStudyPeriodTo(e.target.value)}
                value={studyPeriodTo}
                options={years}
                type="number"
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            Forecast Year:
          </Box>
          <Box flex="80">
            <Box display="flex" gap={4}>
              <Input
                style={{ width: "100%" }}
                onChange={(e) => setForecastYearFrom(e.target.value)}
                value={forecastYearFrom}
                options={years}
                type="number"
              />
              <Input
                style={{ width: "100%" }}
                onChange={(e) => setForecastYearTo(e.target.value)}
                value={forecastYearTo}
                options={years}
                type="number"
              />
            </Box>
          </Box>
        </Box>
        {/* <Box display='flex' alignItems='center'>
                    <Box flex='20' color={' #000000DA'} fontSize={'16px'}><span style={{ color: 'red' }}>*</span>Category:</Box>
                    <Box flex='80' display='flex' gap={4}>
                        <Box flex='35'>
                            <Select placeholder="Select item" style={{ width: '100%' }} options={categoriesArr} value={category} onChange={(value) => setCategory(value)} />
                            {errors.category && <span style={spanErr}>{errors.category}</span>}
                        </Box>
                        <Box flex='65' display='flex' alignItems='center'>
                            <Box flex='40' color={' #000000DA'} fontSize={'16px'}><span style={{ color: 'red' }}>*</span>Sub Category:</Box>
                            <Box flex='60'>
                                <Select placeholder="Select Item" style={{ width: '100%' }} options={subCategoryArr} value={subCategory} onChange={(value) => setSubCategory(value)} disabled={category.length < 1} />
                                {errors.subCategory && <span style={spanErr}>{errors.subCategory}</span>}
                            </Box>
                        </Box>
                    </Box>
                </Box> */}
        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Publish Date:
          </Box>
          <Box flex="80">
            <DatePicker
              value={publishDate ? dayjs(publishDate) : ""}
              format="YYYY-MM-DD"
              style={{ width: "100%" }}
              onChange={(date, dateString) => setPublishDate(dateString)}
            />
            {errors.publishDate && (
              <span style={spanErr}>{errors.publishDate}</span>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            <span style={spanErr}>*</span>Batch:
          </Box>
          <Box flex="80">
            <Select
              showSearch
              style={{ width: "100%" }}
              onChange={(value) => setBatch(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={batch}
            >
              {generateOptions(new Date()).map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            {errors.batch && <span style={spanErr}>{errors.batch}</span>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            {/* <span style={{ color: "red" }}>*</span> */}
            Region Covered:
          </Box>
          <Box flex="80" display="flex" gap={4}>
            <Box flex="35">
              {/* <Select {...sharedRegionProps} value={region} onChange={(e.target.value)}/> */}
              <Select
                {...sharedRegionProps}
                value={region}
                onChange={handleChangeRegion}
              />
              {errors.region && <span style={spanErr}>{errors.region}</span>}
            </Box>
            <Box flex="65" display="flex" alignItems="center">
              <Box flex="40" color={" #000000DA"} fontSize={"16px"}>
                {/* <span style={{ color: "red" }}>*</span> */}
                Country Covered:
              </Box>
              <Box flex="60">
                <Select
                  {...sharedCountryProps}
                  value={country}
                  disabled={region.length < 1}
                  onChange={(value) => {
                    setCountry(value);
                  }}
                />
                {errors.country && (
                  <span style={spanErr}>{errors.country}</span>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Box display='flex' alignItems='center'>
                    <Box flex='20' color={' #000000DA'} fontSize={'16px'}><span style={{ color: 'red' }}>*</span>Publish Date</Box>
                    <Box flex='80'>
                        <DatePicker style={{ width: '100%' }} onChange={onChange} />
                        {errors.publishDate && <span style={spanErr}>{errors.publishDate}</span>}

                    </Box>
                </Box> */}
        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Type:
          </Box>
          <Box flex="80">
            <Select
              style={{ width: "100%" }}
              value={type}
              onChange={(value) => setType(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {typeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            {errors.type && <span style={spanErr}>{errors.type}</span>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            {/* <span style={{ color: "red" }}>*</span> */}
            Clean Type:
          </Box>
          <Box flex="80">
            <Select
              defaultValue="report"
              style={{ width: "100%" }}
              onChange={(value) => setCleanType(value)}
              value={cleanType}
              options={[
                {
                  value: "report",
                  label: "Report",
                },
                {
                  value: "customReport",
                  label: "Custom Report",
                },
              ]}
            />
            {errors.cleanType && (
              <span style={spanErr}>{errors.cleanType}</span>
            )}
          </Box>
        </Box>
        <Box>
          {deepCategoryData.length > 0 && (
            <RecursiveSelect
              data={deepCategoryData}
              onChange={handleChange}
              count={0}
              onRemove={handleRemove}
              parentValue={null}
              preselectedValues={deepCatArry}
            />
          )}
          <Box ml={"20%"}>
            {errors.deepCategory && (
              <span style={spanErr}>{errors.deepCategory}</span>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Version:
          </Box>
          <Box flex="80">
            <Input
              placeholder="Version"
              type="number"
              min="0"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            />
            {errors.version && <span style={spanErr}>{errors.version}</span>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            Price:
          </Box>
          <Box flex="80" display="flex" gap={4}>
            <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
              <span style={{ color: "red" }}>*</span>Single:{" "}
              <Input defaultValue="4750" disabled />{" "}
            </Box>
            <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
              <span style={{ color: "red" }}>*</span>Team:{" "}
              <Input defaultValue="5250" disabled />{" "}
            </Box>
            <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
              <span style={{ color: "red" }}>*</span>Corporate:{" "}
              <Input defaultValue="8750" disabled />{" "}
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Page Number:
          </Box>
          <Box flex="30">
            <InputNumber
              type="number"
              style={{ width: "100%" }}
              value={pageNumber}
              onChange={(value) => {
                if (value !== null && !isNaN(value)) setPageNumber(value);
              }}
              min={1}
              max={10000}
              precision={0}
            />
            {errors.pageNumber && (
              <span style={spanErr}>{errors.pageNumber}</span>
            )}
          </Box>
          <Box
            flex="25"
            color={"#000000DA"}
            fontSize={"16px"}
            style={{
              marginLeft: "10px",
            }}
          >
            <span style={{ color: "red" }}>*</span>Dispatched Client ID:
          </Box>
          <Box flex="25">
            <Input
              value={dispatchedClientId}
              onChange={(e) => setDispatchedClientId(e.target.value)}
            />
            {errors.dispatchedClientId && (
              <span style={spanErr}>{errors.dispatchedClientId}</span>
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            {/* <span style={{ color: "red" }}>*</span> */}
            File Type:
          </Box>
          <Box flex="80">
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setFileType(value)}
              value={fileType}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {fileTypeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            {errors.fileType && <span style={spanErr}>{errors.fileType}</span>}
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Website Title Status:
          </Box>
          <Box flex="80">
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setWebsiteTitleStatus(value)}
              value={websiteTitleStatus}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {websiteTitleOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            {errors.websiteTitleStatus && (
              <span style={spanErr}>{errors.websiteTitleStatus}</span>
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            Owner:
          </Box>
          <Box flex="80">
            <Input
              style={{ width: "100%" }}
              value={owner}
              onChange={(e) => setOwner(e.target.value)}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Sales Person:
          </Box>
          <Box flex="80">
            <Select
              showSearch
              style={{ width: "100%" }}
              onChange={(value) => setSalesPerson(value)}
              value={salesPerson}
              filterOption={(input, option) =>
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }
            >
              {modifiedRelationshipManagerEmailData.map((user) => (
                <Option key={user.value} value={user.value}>
                  {user.label}
                </Option>
              ))}
            </Select>
            {errors.salesPerson && (
              <span style={spanErr}>{errors.salesPerson}</span>
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            {/* <span style={{ color: "red" }}>*</span> */}
            Fresh Sale ID:
          </Box>
          <Box flex="80">
            <Input
              style={{ width: "100%" }}
              onChange={(e) => setFreshSaleId(e.target.value)}
              value={freshSaleId}
            />
            {errors.freshSaleId && (
              <span style={spanErr}>{errors.freshSaleId}</span>
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box flex="20" color={"#000000DA"} fontSize={"16px"}>
            Additional Comment:
          </Box>
          <Box flex="80">
            <Input.TextArea
              style={{ width: "100%" }}
              onChange={(e) => setAdditionalComments(e.target.value)}
              value={additionalComments}
            />
            {errors.additionalComment && (
              <span style={spanErr}>{errors.additionalComment}</span>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>NDA:
          </Box>
          <Box flex="80">
            <Select
              defaultValue="No"
              style={{ width: "100%" }}
              onChange={(value) => setNDAValue(value)}
              value={ndaValue}
            >
              {NDA_Options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            {errors.nda && <span style={spanErr}>{errors.nda}</span>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            <span style={{ color: "red" }}>*</span>Publish Report to:
          </Box>
          <Box flex="80">
            <Select
              defaultValue="library"
              style={{ width: "100%" }}
              onChange={(value) => setPublishReportTo(value)}
              value={publishReportTo}
            >
              {[
                { label: "On Demand", value: "on_demand" },
                { label: "Library", value: "library" },
              ].map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            {errors.nda && <span style={spanErr}>{errors.nda}</span>}
          </Box>
        </Box>
          <Box display="flex" alignItems="center">
            <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
              Visible to Everyone:
            </Box>
            <Box flex="80">
            <Checkbox
              checked={visibleToAll}
              onChange={(e) => {
                setVisibleToAll(e.target.checked);
              }}
            />
          </Box>
          </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            Publish report
          </Box>
          <Box flex="80">
            <Checkbox
              checked={publishReport}
              onChange={(e) => {
                setPublishReport(e.target.checked);
              }}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            Active:
          </Box>
          <Box flex="80">
            <span style={{ color: active ? "green" : "red", fontSize: "16px" }}>
              {active ? "TRUE" : "FALSE"}
            </span>
          </Box>
        </Box>

        {/* //Add new field custom Report field checkbox */}
        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            Custom Report:
          </Box>
          <Box flex="80">
            <Checkbox
              checked={customReport}
              onChange={(e) => {
                setCustomReport(e.target.checked);
              }}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box flex="20" color={" #000000DA"} fontSize={"16px"}>
            myRA Chat:
          </Box>
          <Box flex="80">
            <Checkbox
              checked={myRaAiAvailable}
              onChange={(e) => {
                setMyRaAiAvailable(e.target.checked);
              }}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button type="primary" onClick={handleSubmit}>
            {hide ? "Update report" : "Publish report"}
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default CreateReport;

const { Option } = Select;

const RecursiveSelect = ({
  data,
  onChange,
  count,
  onRemove,
  parentValue,
  preselectedValues = [],
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (preselectedValues.length > 0) {
      setSelectedValue(preselectedValues[0]);
    }
  }, [preselectedValues]);

  const handleSelect = (value) => {
    setSelectedValue(value);
    onChange(value, count, true);
  };

  const handleRemove = () => {
    onRemove(parentValue);
    onChange(parentValue, count, false);
  };

  const children = data.find((item) => item.value === selectedValue)?.children;

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box flex="20" color="#000000DA" fontSize="16px" my={2.5}>
          <span style={{ color: "red" }}>*</span>
          {count < 1 ? "Category" : "Sub Category"}
        </Box>
        <Box flex="80" display={"flex"}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select an option"
            onChange={handleSelect}
            value={selectedValue}
          >
            {data?.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
          {count > 0 && (
            <Box ml={1}>
              <Button
                shape="circle"
                icon={<MinusCircleOutlined />}
                disabled={selectedValue === null}
                type="primary"
                danger
                onClick={handleRemove}
              />
            </Box>
          )}
        </Box>
      </Box>
      {selectedValue && children?.length > 0 && (
        <RecursiveSelect
          data={children}
          onChange={onChange}
          count={count + 1}
          onRemove={onRemove}
          parentValue={selectedValue}
          preselectedValues={preselectedValues.slice(1)}
        />
      )}
    </Box>
  );
};
