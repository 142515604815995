import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import axios from "axios";
import { Button, MenuItem, Select, Typography } from "@mui/material";
import { Input, Tabs } from "antd";
import Loader from "../../components/Common/Loader/Loading";

import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { useLazyUploadBlurPdfQuery } from "../../redux/api/createUserApi";

const UploadBlurFiles = () => {
  const [file, setFile] = useState(null);
  const [
    uploadBlurPdf,
    { data: uploadData, error: uploadError, isLoading: uploadLoading },
  ] = useLazyUploadBlurPdfQuery();
  const { Base_URL } = API;
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState("");
  const [labelData, setLabelData] = useState([]);

  const dispatch = useDispatch();
  // const [isChecked, setIsChecked] = useState(false);
  const cookies = new Cookies();
  let token = cookies.get("TOKEN");
  const [searchType, setSearchType] = useState("title");
  const [loadingQuery, setLoadingQuery] = useState(false);

  // Function to perform API call for searching files
  const searchFiles = async (query) => {
    // setLoadingQuery(true)
    try {
      const response = await axios.get(
        `${Base_URL}searchSynapseReports?${searchType}=${query}`
      );
      setSearchResults(response.data.data);
    } catch (error) {
      console.error("Error searching files:", error);
    } finally {
      setLoadingQuery(false);
    }
  };

  // Debouncing mechanism to delay API calls
  useEffect(() => {
    setLoadingQuery(true);
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        searchFiles(searchQuery);
      } else {
        setSearchResults([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    if (uploadError) {
      toast.error("Error uploading file");
    }
  }, [uploadError]);

  // Function to handle selecting a search result
  const handleSelectResult = (result) => {
    setSearchQuery(""); // Set search query to selected result
    setSelectedResult(result);
    //   setReportId()
    setSearchResults([]); // Clear search results
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      uploadBlurPdf({
        id: selectedResult.synapse_report_id,
      })
        .then((res) => {
          toast.success(res?.data?.message);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(
        "Error uploading file, Do know larger file size may take longer time to upload and limit is 100 seconds. Please have good internet connection."
      );
    }
    // finally {
    //   setLoading(false);
    // }
  };

  return (
    <>
      {/* <Typography>Upload Files Here</Typography> */}
      <div style={{ width: "70%", justifyContent: "center", margin: "auto" }}>
        <Loader show={loading || uploadLoading} />
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
        >
          <Typography
            sx={{
              flex: "3",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              marginTop: "6px",
              fontSize: "17px",
            }}
          >
            Search Report :{" "}
          </Typography>
          <div style={{ flex: "7", display: "flex" }}>
            <div style={{ width: "20%" }}>
              <Select
                value={searchType}
                onChange={(e) => {
                  setSearchType(e.target.value);
                  setSelectedResult("");
                  setSearchQuery("");
                }}
                style={{
                  width: "100%",
                  height: "30px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <MenuItem value="title">Title</MenuItem>
                <MenuItem value="slug">Slug</MenuItem>
                <MenuItem value="id">ID</MenuItem>
              </Select>
            </div>
            <div style={{ position: "relative", width: "80%", gap: "10px" }}>
              <Input
                type={searchType == "id" ? "number" : "text"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Input here"
                style={{
                  width: "100%",
                  height: "30px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              />
              {searchQuery.length > 0 &&
                searchResults.length == 0 &&
                !loadingQuery && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 1,
                      padding: "0.5rem",
                      margin: 0,
                      borderRadius: "0 0 5px 5px",
                      maxHeight: "300px",
                      overflowY: "scroll",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    No data found{" "}
                  </div>
                )}
              {searchResults.length > 0 && (
                <ul
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    zIndex: 1,
                    padding: "0.5rem",
                    margin: 0,
                    borderRadius: "0 0 5px 5px",
                    maxHeight: "300px",
                    overflowY: "scroll",
                    cursor: "pointer",
                  }}
                >
                  {searchResults?.map((result, index) => (
                    <li
                      key={index}
                      style={{
                        borderBottom: "1px solid lightgrey",
                        padding: "5px",
                      }}
                      onClick={() => handleSelectResult(result)}
                    >
                      {result.title} - {result.forecast_year}
                    </li>
                  ))}
                </ul>
              )}
              {selectedResult.title && (
                <div>
                  {selectedResult.title}
                  <button
                    onClick={() => setSelectedResult("")}
                    style={{
                      color: "white",
                      background: "red",
                      padding: "5px",
                      border: "none",
                      marginLeft: "10px",
                    }}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              //   flex: "3",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              //   marginRight: "10px",
            }}
          >
            <Button
              variant="contained"
              color="success"
              disabled={!selectedResult || loading || uploadLoading}
              onClick={() => handleSubmit()}
            >
              GENERATE
            </Button>
          </div>

          {/* <div style={{ display: "flex", gap: "10px", flex: "7" }}></div> */}
        </div>
        {uploadData?.url && (
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
          >
            <Typography
              sx={{
                flex: "3",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                marginTop: "6px",
                fontSize: "17px",
              }}
            >
              Blur PDF:
            </Typography>
            <div style={{ flex: "7", display: "flex" }}>
              <div style={{ position: "relative", width: "100%", gap: "10px" }}>
                <a
                  href={uploadData.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {uploadData.url}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadBlurFiles;
