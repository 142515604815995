import React, { useState, useEffect } from "react";
import API from "../../utils/api";
import axios from "axios";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Input, List, Space, Tabs } from "antd";
import Loader from "../../components/Common/Loader/Loading";

import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { useLazyUploadBlurPdfQuery } from "../../redux/api/createUserApi";
import {
  assignReportApi,
  useDeleteSynapseReportVersionMutation,
  useLazyGetSynapseReportVersionsQuery,
} from "../../redux/api/assignReportApi";

const VersionControlled = () => {
  const [file, setFile] = useState(null);
  const [
    uploadBlurPdf,
    { data: uploadData, error: uploadError, isLoading: uploadLoading },
  ] = useLazyUploadBlurPdfQuery();
  const { Base_URL } = API;
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState("");
  const [labelData, setLabelData] = useState([]);

  const dispatch = useDispatch();
  // const [isChecked, setIsChecked] = useState(false);
  const cookies = new Cookies();
  let token = cookies.get("TOKEN");
  const [searchType, setSearchType] = useState("title");
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [documentDatas, setDocumentDatas] = useState([]);

  // Function to perform API call for searching files
  const searchFiles = async (query) => {
    // setLoadingQuery(true)
    try {
      const response = await axios.get(
        `${Base_URL}searchSynapseReports?${searchType}=${query}`
      );
      setSearchResults(response.data.data);
    } catch (error) {
      console.error("Error searching files:", error);
    } finally {
      setLoadingQuery(false);
    }
  };

  // Debouncing mechanism to delay API calls
  useEffect(() => {
    setLoadingQuery(true);
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        searchFiles(searchQuery);
      } else {
        setSearchResults([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    if (uploadError) {
      toast.error("Error uploading file");
    }
  }, [uploadError]);

  // Function to handle selecting a search result
  const handleSelectResult = (result) => {
    setSearchQuery(""); // Set search query to selected result
    setSelectedResult(result);
    //   setReportId()
    setSearchResults([]); // Clear search results
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      uploadBlurPdf({
        id: selectedResult.synapse_report_id,
      })
        .then((res) => {
          toast.success(res?.data?.message);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(
        "Error uploading file, Do know larger file size may take longer time to upload and limit is 100 seconds. Please have good internet connection."
      );
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const [
    triggerFetch,
    {
      data: reportVersions,
      error: reportVersionsError,
      isLoading: reportVersionsLoading,
    },
  ] = useLazyGetSynapseReportVersionsQuery();

  const [
    deleteSynapseReportVersion,
    { data: deleteData, error: deleteError, isLoading: deleteLoading },
  ] = useDeleteSynapseReportVersionMutation();

  useEffect(() => {
    if (selectedResult) {
      triggerFetch({ reportId: selectedResult.synapse_report_id });
    }
  }, [selectedResult]);

  useEffect(() => {
    if (reportVersions) {
      setDocumentDatas(reportVersions);
    }
  }, [reportVersions]);

  const handleDelete = (document_id, type, report_id) => {
    deleteSynapseReportVersion({ document_id, type, report_id })
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
  };

  return (
    <>
      {/* <Typography>Upload Files Here</Typography> */}
      <div style={{ width: "70%", justifyContent: "center", margin: "auto" }}>
        <Loader show={deleteLoading || reportVersionsLoading} text="Loading" />
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
        >
          <Typography
            sx={{
              flex: "3",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "10px",
              marginTop: "6px",
              fontSize: "17px",
            }}
          >
            Search Report :{" "}
          </Typography>
          <div style={{ flex: "7", display: "flex" }}>
            <div style={{ width: "20%" }}>
              <Select
                value={searchType}
                onChange={(e) => {
                  setSearchType(e.target.value);
                  setSelectedResult("");
                  setSearchQuery("");
                }}
                style={{
                  width: "100%",
                  height: "30px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <MenuItem value="title">Title</MenuItem>
                <MenuItem value="slug">Slug</MenuItem>
                <MenuItem value="id">ID</MenuItem>
              </Select>
            </div>
            <div style={{ position: "relative", width: "80%", gap: "10px" }}>
              <Input
                type={searchType == "id" ? "number" : "text"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Input here"
                style={{
                  width: "100%",
                  height: "30px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              />
              {searchQuery.length > 0 &&
                searchResults.length === 0 &&
                !loadingQuery && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 1,
                      padding: "0.5rem",
                      margin: 0,
                      borderRadius: "0 0 5px 5px",
                      maxHeight: "300px",
                      overflowY: "scroll",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    No data found{" "}
                  </div>
                )}
              {searchResults.length > 0 && (
                <ul
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    zIndex: 1,
                    padding: "0.5rem",
                    margin: 0,
                    borderRadius: "0 0 5px 5px",
                    maxHeight: "300px",
                    overflowY: "scroll",
                    cursor: "pointer",
                  }}
                >
                  {searchResults?.map((result, index) => (
                    <li
                      key={index}
                      style={{
                        borderBottom: "1px solid lightgrey",
                        padding: "5px",
                      }}
                      onClick={() => {
                        dispatch(assignReportApi.util.resetApiState());
                        handleSelectResult(result);
                      }}
                    >
                      {result.title} - {result.forecast_year}
                    </li>
                  ))}
                </ul>
              )}
              {selectedResult.title && (
                <div>
                  {selectedResult.title}
                  <button
                    onClick={() => {
                      setSelectedResult("");
                      setDocumentDatas([]);
                      dispatch(assignReportApi.util.resetApiState());
                      //Also clear the cache synapse report versions query
                    }}
                    style={{
                      color: "white",
                      background: "red",
                      padding: "5px",
                      border: "none",
                      marginLeft: "10px",
                    }}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {documentDatas?.pdfData?.length > 0 && (
          <Box>
            <h3>PDF Versions</h3>
            <TableContainer
              component={Paper}
              style={{ flex: "7", display: "flex" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      File Name
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      URL
                    </TableCell>
                    <TableCell
                      style={{
                        width: "10%",
                      }}
                    >
                      Version
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      Comment
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20%",
                      }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      style={{
                        width: "10%",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentDatas?.pdfData?.map((row) => (
                    <TableRow key={row.document_id}>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        {row.file_name}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        <a
                          href={row.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View PDF
                        </a>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                        }}
                      >
                        {row.version}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        {row.comment}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        {new Date(row.createdAt).toLocaleString()}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#f44336",
                          }}
                          onClick={() =>
                            handleDelete(
                              row.document_id,
                              "report_pdfs",
                              selectedResult.synapse_report_id
                            )
                          }
                          // onClick={() => handleDelete(row.document_id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {documentDatas?.excelData?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "3rem",
            }}
          >
            <Box
              style={{
                width: "100%",
              }}
            >
              <h3>Excel Versions</h3>
              <TableContainer
                component={Paper}
                style={{ flex: "7", display: "flex" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        File Name
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        URL
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                        }}
                      >
                        Version
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        Comment
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        Created At
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentDatas?.excelData?.map((row) => (
                      <TableRow key={row.document_id}>
                        <TableCell
                          style={{
                            width: "20%",
                          }}
                        >
                          {row.file_name}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "20%",
                          }}
                        >
                          <a
                            href={row.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Excel
                          </a>
                        </TableCell>
                        <TableCell
                          style={{
                            width: "10%",
                          }}
                        >
                          {row.version}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "20%",
                          }}
                        >
                          {row.comment}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "20%",
                          }}
                        >
                          {new Date(row.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "10%",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#f44336",
                            }}
                            onClick={() =>
                              handleDelete(
                                row.document_id,
                                "report_excel",
                                selectedResult.synapse_report_id
                              )
                            }
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
        {documentDatas?.pptData?.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "3rem",
            }}
          >
            <Box
              style={{
                width: "100%",
              }}
            >
              <h3>PPT Versions</h3>
              <TableContainer
                component={Paper}

                // style={{ flex: "7", display: "flex" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        File Name
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        URL
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                        }}
                      >
                        Version
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        Comment
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                        }}
                      >
                        Created At
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentDatas?.pptData?.map((row) => (
                      <TableRow key={row.document_id}>
                        <TableCell
                          style={{
                            width: "20%",
                          }}
                        >
                          {row.file_name}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "20%",
                          }}
                        >
                          <a
                            href={row.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View PPT
                          </a>
                        </TableCell>
                        <TableCell
                          style={{
                            width: "10%",
                          }}
                        >
                          {row.version}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "20%",
                          }}
                        >
                          {row.comment}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "20%",
                          }}
                        >
                          {new Date(row.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell
                          style={{
                            width: "10%",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#f44336",
                            }}
                            onClick={() =>
                              handleDelete(
                                row.document_id,
                                "report_ppt",
                                selectedResult.synapse_report_id
                              )
                            }
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
        {(documentDatas?.message ||
          (documentDatas?.pdfData?.length === 0 &&
            documentDatas?.excelData?.length === 0 &&
            documentDatas?.pptData?.length === 0)) && (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            {/* <Typography>No Versions Found</Typography> */}
            {/* //Write in big font */}
            <Typography variant="h6">
              {documentDatas?.message ?? "No files found for the report"}
            </Typography>
          </Box>
        )}
        {/* {documentDatas?.message(<Typography>No data found</Typography>)} */}
      </div>
    </>
  );
};

export default VersionControlled;
