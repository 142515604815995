import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

const Loader = ({ show, text = "Uploading" }) => {
  if (!show) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.7)", // semi-transparent white background
        zIndex: 9999, // ensure the loader is on top of everything
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <CircularProgress />
        <Typography>{text}</Typography>
      </div>
    </div>
  );
};

export default Loader;
