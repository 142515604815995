import React from "react";
import UploadFiles from "./newUploadFiles";
import UploadBlurFiles from "./UploadBlur";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import VersionControlled from "./VersionControlled";

const index = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Upload Files Here" key="1">
        <UploadFiles />
      </TabPane>
      <TabPane tab="Upload Blur PDF" key="2">
        <UploadBlurFiles />
      </TabPane>
      <TabPane tab="Version Controlled" key="3">
        <VersionControlled />
      </TabPane>
    </Tabs>
  );
};

export default index;
