import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Input, AutoComplete, Spin, Button, Flex, Switch } from "antd";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import axios from "axios";
import CreateReport from "../CreateReport";
import API from "../../utils/api";

import { Space, Table, Tag } from "antd";
import Cookies from "universal-cookie";
import "../UserManagement/CMSUserList/UserList.css";
import {
  assignReportApi,
  useLazyGetSynapseReportsQuery,
} from "../../redux/api/assignReportApi";
import Fuse from "fuse.js";
import _ from "lodash";
import { useDispatch } from "react-redux";

function formatDateToDMY(originalDate) {
  const parsedDate = new Date(originalDate);
  const day = parsedDate.getDate();
  const month = parsedDate.getMonth() + 1; // Months are zero-based
  const year = parsedDate.getFullYear();

  // Add leading zero if day or month is a single digit
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}-${formattedMonth}-${year}`;
}

const { Option } = AutoComplete;

const UpdateReport = () => {
  // const [data, setData] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [isCustomReport, setIsCustomReport] = useState(false);
  const [hide, setHide] = useState(false);
  const effectRan = useRef(false);
  const [
    getSynapseReports,
    {
      data: synapseReportsData,
      error: synapseReportsError,
      isLoading: initSynapseReportsLoading,
      isError,
    },
  ] = useLazyGetSynapseReportsQuery();
  let fuse;
  useEffect(() => {
    // if (!effectRan.current) {
    getSynapseReports({
      isPublished: isPublished,
      // searchText: searchText,
      // isCustomReport: isCustomReport,
    });
    // }

    // return () => (effectRan.current = true);
  }, []);
  useEffect(() => {
    if (synapseReportsData) {
      let clonedData = _.cloneDeep(synapseReportsData.data);

      if (isCustomReport) {
        clonedData = clonedData.filter((item) => item.custom_report === true);
      }
      if (searchText) {
        const options = {
          includeScore: true,
          minMatchCharLength: 3,
          keys: ["title"],
        };
        fuse = new Fuse(clonedData ?? [], options);
        const result = fuse.search(searchText);
        setReportsData(result.map((result) => result.item));
        return;
      }

      setReportsData(clonedData);
    }
  }, [synapseReportsData]);

  const columns = [
    {
      title: "ID",
      dataIndex: "synapse_report_id",
      key: "synapse_report_id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => (
        <Space size="middle">
          {record.title}{" "}
          {record?.report_publish_to === "on_demand" && (
            <Tag color="blue">On Demand Report</Tag>
          )}
        </Space>
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Year",
      dataIndex: "base_year",
      key: "base_year",
    },
    {
      title: "Parent Report",
      key: "parent_rd",
      dataIndex: "parent_rd",
      render: (_, record) => (
        <Space size="middle">{record?.parent_rd?.title}</Space>
      ),
    },
    {
      title: "Forecast Year",
      key: "forecast_year",
      dataIndex: "forecast_year",
    },
    {
      title: "Version",
      key: "version",
      dataIndex: "version",
    },
    {
      title: "Last Modified",
      key: "updatedAt",
      dataIndex: "updatedAt",
      render: (text) => <p>{formatDateToDMY(text)}</p>,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => deactiveReport(record.synapse_report_id)}>
            {record.active ? "Deactivate" : "Activate"}
          </Button>
          <Button onClick={() => onSelect(record._id)}>Update</Button>
        </Space>
      ),
    },
  ];

  const { Base_URL, searchReport } = API;
  const cookies = new Cookies();
  let token = cookies.get("TOKEN");
  const dispatch = useDispatch();

  const fetchReports = async (
    isPublished = true,
    searchText = "",
    isCustomReport = false
  ) => {
    setLoading(true);
    try {
      // Perform API request to fetch options based on value
      const response = await axios.get(
        `${Base_URL}getSynapseReports?published=${isPublished}&search=${searchText}&custom=${isCustomReport}`
      );
      const data = response.data.data;
      setReportsData(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false); // Set loading to false when request completes
    }
  };
  const deactiveReport = async (id) => {
    try {
      const response = await axios({
        url: `${Base_URL}deleteSynapseReport/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      // const response = await axios.patch(
      //   `${Base_URL}deleteSynapseReport/${id}`
      // );
      const data = response.data.message;
      if (data) {
        toast.success(data, {
          position: "top-right",
          autoClose: 5000,
        });

        await dispatch(assignReportApi.util.resetApiState());
        setLoading(true);
        await getSynapseReports({
          isPublished: isPublished,
        })
          .then(() => {
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching options:", error);
            setLoading(false);
          });
      } else {
        toast.error(data, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const filteredData = reportsData?.filter((item) =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  );

  // const filteredData = reportsData?.filter((item) => {
  //   if (
  //     item.title.toLowerCase().includes(searchText.toLowerCase()) &&
  //     item.published === isPublished
  //   ) {
  //     return item;
  //   }
  // });

  // useEffect(() => {
  //   fetchReports();
  // }, [isCustomReport]);

  useEffect(() => {
    if (isCustomReport) {
      //Set report which have custom_report as true
      const customReportData = reportsData.filter(
        (item) => (item.custom_report === true || item?.visible_to_all === false)
      );
      setReportsData(customReportData);
    } else {
    }
  }, [isCustomReport]);

  // const debouncedSearch = useCallback(
  //   debounce((nextValue) => {
  //     setSearchText(nextValue);
  //     getSynapseReports({
  //       isPublished: isPublished,
  //       searchText: nextValue,
  //       isCustomReport: isCustomReport,
  //     });
  //   }, 100),
  //   [] // Dependency array
  // );

  const onSelect = (value) => {
    setHide(true);
    const sessionData = reportsData.filter((item) => item._id === value);

    // Here we are storing the data whick we want to update in the CreateReport component
    localStorage.setItem("FOR_UPDATE", JSON.stringify(sessionData));
    localStorage.setItem("HIDE_UPDATE", true);
    // setOptions([]);
  };

  const handlePublish = async (checked) => {
    setLoading(true);
    setIsPublished(checked);
    dispatch(assignReportApi.util.resetApiState());
    getSynapseReports({
      isPublished: checked,
    })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
        setLoading(false);
      });
  };

  const handleSearchReport = (value, isCustomReportParam = false) => {
    if (value === "") {
      if (isCustomReportParam) {
        const customReportData = synapseReportsData?.data.filter(
          (item) => item.custom_report === true
        );
        setReportsData(customReportData);
        return;
      }
      setReportsData(synapseReportsData?.data ?? []);
      return;
    } else {
      const options = {
        includeScore: true,
        minMatchCharLength: 3,
        keys: ["title"],
      };
      let clonedSynapseReportsData = _.cloneDeep(synapseReportsData?.data);
      if (isCustomReportParam) {
        clonedSynapseReportsData = clonedSynapseReportsData?.filter(
          (item) => (item.custom_report === true || item?.visible_to_all === false)
        );
      }
      fuse = new Fuse(clonedSynapseReportsData ?? [], options);
      const result = fuse.search(value);
      setReportsData(result.map((result) => result.item));
    }
  };

  const handleCustomReports = (checked, searchTextParam) => {
    setIsCustomReport(checked);
    if (searchTextParam === "") {
      const clonedExistingData = _.cloneDeep(reportsData);
      if (checked) {
        const customReportData = clonedExistingData.filter(
          (item) => (item.custom_report === true || item?.visible_to_all === false)
        );
        setReportsData(customReportData);
      } else {
        setReportsData(synapseReportsData?.data);
      }
    } else {
      handleSearchReport(searchTextParam, checked);
    }
  };
  const debouncedSearch = debounce(handleSearchReport, 300);

  return (
    <>
      {hide ? (
        <Box>
          <Box mx="20%" width={"100%"} my="2%">
            <Button
              icon={<LeftOutlined />}
              onClick={() => {
                localStorage.removeItem("HIDE_UPDATE");
                localStorage.removeItem("FOR_UPDATE");
                setHide(false);
                //Also set existing search text to search input
                setSearchText(searchText);
              }}
            >
              Go Back
            </Button>
          </Box>
          <CreateReport
            hide={hide}
            setHide={setHide}
            handleRefetch={() => {
              dispatch(assignReportApi.util.resetApiState());
              setLoading(true);
              getSynapseReports({
                isPublished: isPublished,
              })
                .then(() => {
                  setLoading(false);
                })
                .catch((error) => {
                  console.error("Error fetching options:", error);
                  setLoading(false);
                });
            }}
          />
        </Box>
      ) : (
        <Box height={"100%"}>
          <Box
            my={2}
            display={"flex"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            gap={2}
          >
            <Input
              style={{
                width: "20%",
                //Highlight the search text
                border: "1px solid #67bfff",
              }}
              placeholder="Search Report"
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={(e) => {
                const value = e.target.value;
                setSearchText(value);
                debouncedSearch(value, isCustomReport);
              }}
            />
            {/* <Input defaultValue="Combine input and button" /> */}
            <Link to="/home/content-management/create-report">
              <Button type="primary">Create New Report</Button>
            </Link>
          </Box>

          <Box
            sx={{
              marginBottom: "0.5rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography sx={{ marginRight: "1rem" }}>
              {reportsData.length}{" "}
              {reportsData.length > 1 ? "reports" : "report"} found
              {searchText && ` for "${searchText}"`}
            </Typography>
            <Switch
              checkedChildren="Published"
              unCheckedChildren="Unpublished"
              onChange={(checked) => handlePublish(checked)}
              checked={isPublished}
              className="custom-switch"
            />
            <Switch
              className="custom-switch2"
              checkedChildren="Custom Report"
              unCheckedChildren="All Reports"
              onChange={(checked) => handleCustomReports(checked, searchText)}
              checked={isCustomReport}
              style={{ marginLeft: "10px" }} // Optional: Add some spacing between the switches
            />
          </Box>
          <Table
            className="custom-table-style"
            style={{ width: "100%" }}
            columns={columns}
            dataSource={reportsData}
            loading={initSynapseReportsLoading || loading}
          />
        </Box>
      )}
    </>
  );
};

export default UpdateReport;
