import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  DatePicker,
  message,
  Form,
  Select,
  Typography,
  Col,
  Row,
  Switch,
} from "antd";
import Cookies from "universal-cookie";
import API from "../../utils/api";
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategoryData,
  setFlashReports,
  removeFlashReport,
  updateFlashReportDate,
} from "../../redux/flashReportAssignment";
import { format } from "date-fns";
import { CATEGORYDATA } from "../../mock/ReportAssignmentData";
import { newReportAssignmentData } from "../../mock/newReportAssignmentData";
import { findMatches } from "./utils";
import _ from "lodash";
import { Box } from "@mui/material";

const { Option } = Select;

const FlashCreate = ({ selectedCustomReportUser, selectedUserHasSemiAccess }) => {
  const cookies = new Cookies();
  const { Base_URL, getFlashReport } = API;
  const token = cookies.get("TOKEN");
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportCount, setReportCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [reportDates, setReportDates] = useState({});
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(undefined);
  const flashReport = useSelector((state) => state.flashReportAssignment.flash);
  const [prevCount, setPrevCount] = useState(0);
  const [count, setCount] = useState(0);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [availableSubcategories, setAvailableSubcategories] = useState([]);
  const [combinedSelection, setCombinedSelection] = useState([]);
  const [selectedReportsData, setSelectedReportsData] = useState([]);
  const [showSelected, setShowSelected] = useState(false);
  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [flashKeys, setFlashKeys] = useState([]);
  const [isCustomFlashReport, setIsCustomFlashReport] = useState(selectedCustomReportUser || false);

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    let baseString = `${Base_URL}${getFlashReport}?custom_report=${isCustomFlashReport}&limit=${1000000}&page=${currentPage}`;
    if (selectedCategories) {
      baseString = `${baseString}&cat_id=${selectedCategories.toString()}&subcat_id=${
        selectedSubcategories.toString() ?? ""
      }`;
    }

    const config = {
      method: "get",
      headers: { authorization: `Bearer ${token}` },
      maxBodyLength: Infinity,
      url: baseString,
    };

    axios(config)
      .then((response) => {
        const flashReportList = response?.data?.data || [];
        setReportCount(response?.data?.meta?.totalDocuments || 0);
        setData(flashReportList);

        const selectedKeys = [];
        const dates = {};
        flashReport.forEach((flash) => {
          const matchingReport = flashReportList.find(
            (report) => report.id === flash.id
          );
          if (matchingReport) {
            selectedKeys.push(matchingReport.id);
            dates[matchingReport.id] = {
              subscription_date: format(
                new Date(flash.subscription_date),
                "yyyy-MM-dd"
              ),
              expiry_date: format(new Date(flash.expiry_date), "yyyy-MM-dd"),
            };
          }
        });
        setSelectedRowKeys(selectedKeys);
        setReportDates(dates);
      })
      .catch((error) => {
        message.error("Failed to load hubs");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isCustomFlashReport]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    const selectedReports = selectedRowKeys.map((reportId) => {
      const subscription_date =
        reportDates[reportId]?.subscription_date ||
        subscriptionDate ||
        format(new Date(dayjs()), "yyyy-MM-dd");
      const expiry_date =
        reportDates[reportId]?.expiry_date ||
        expiryDate ||
        format(new Date(dayjs()), "yyyy-MM-dd");
      return {
        id: reportId,
        title:
          data.find((report) => report.id === reportId)?.title ||
          "Unknown Title",
        subscription_date: subscription_date,
        expiry_date: expiry_date,
        custom_on_demand: data.find((report) => report.id === reportId && report['custom_on_demand'])?.custom_on_demand || false,
      };
    });
    dispatch(setFlashReports(selectedReports));
  };

  const removeKey = (obj, keyToRemove) => {
    const { [keyToRemove]: _, ...updatedObj } = obj;
    return updatedObj;
  };

  const handleDateChange = (dateType, reportId, date) => {
    setReportDates((prevDates) => ({
      ...prevDates,
      [reportId]: {
        ...prevDates[reportId],
        [dateType]: date,
      },
    }));
    dispatch(updateFlashReportDate({ reportId, dateType, date }));
  };

  const handleRemove = (id) => {
    setReportDates(removeKey(reportDates, id));
    setSelectedRowKeys((prevKeys) => prevKeys.filter((key) => key !== id));
    setSelectedReportsData((prevKeys) => prevKeys.filter((key) => key !== id));
    dispatch(removeFlashReport(id));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Subscription Date",
      dataIndex: "subscription_date",
      key: "subscription_date",
      render: (text, record) => {
        const isEnabled = selectedRowKeys.some((item) => item === record.id);
        return (
          <DatePicker
            disabled={!isEnabled}
            value={
              reportDates[record.id]?.subscription_date
                ? dayjs(reportDates[record.id].subscription_date)
                : subscriptionDate || dayjs()
            }
            onChange={(date, dateString) =>
              handleDateChange("subscription_date", record.id, dateString)
            }
            minDate={dayjs()}
          />
        );
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (text, record) => {
        const isEnabled = selectedRowKeys.some((item) => item === record.id);
        return (
          <DatePicker
            disabled={!isEnabled}
            value={
              reportDates[record.id]?.expiry_date
                ? dayjs(reportDates[record.id].expiry_date)
                : expiryDate || dayjs()
            }
            onChange={(date, dateString) =>
              handleDateChange("expiry_date", record.id, dateString)
            }
            minDate={dayjs()}
          />
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelect: (record, selected, selectedRows) => {
      setSelectedValue(record.id);
      if (!selected) {
        handleRemove(record.id);
      }
    },
    // Disable "Select All"
    onSelectAll: (selected, selectedRows, changeRows) => {
      changeRows.map((item) => {
        handleRemove(item.id);
      });
    },
  };

  const handleSubscriptionDateChange = (date) => {
    setSubscriptionDate(date);
    handleAllDateChange("subscription_date", date);
  };

  const handleExpiryDateChange = (date) => {
    setExpiryDate(date);
    handleAllDateChange("expiry_date", date);
  };

  const handleAllDateChange = (dateType, newDate) => {
    selectedRowKeys.forEach((reportId) => {
      const stringReportId = String(reportId);
      setReportDates((prevDates) => ({
        ...prevDates,
        [stringReportId]: {
          ...prevDates[stringReportId],
          [dateType]: newDate,
        },
      }));
      dispatch(updateFlashReportDate({ reportId, dateType, date: newDate }));
    });
  };

  useEffect(() => {
    if (selectedCategories.length > 0) {
      const subcategories = CATEGORYDATA.filter((category) =>
        selectedCategories.includes(category.id)
      ).flatMap((category) => category.subcategory);
      setAvailableSubcategories(subcategories);

      const newSubCategoryIds = [];
      selectedCategories.forEach((category) => {
        combinedSelection.forEach((selection) => {
          const [catId, subCatId] = selection.split("-");
          if (catId === category.toString()) {
            newSubCategoryIds.push(subCatId);
          }
        });
      });
      setSelectedSubcategories(newSubCategoryIds);
    } else {
      setAvailableSubcategories([]);
      setSelectedSubcategories([]);
    }
  }, [selectedCategories]);

  const resetSelection = () => {
    setSelectedValue(undefined);
    setSelectedCategories([]);
    setSelectedSubcategories([]);
    setAvailableSubcategories([]);
    setCombinedSelection([]);
    setSelectedIds([[]]); // Reset selected IDs
    setSubCategories([newReportAssignmentData]); // Reset to initial categories

    form.resetFields();
  };
  useEffect(() => {
    const keys = flashReport.map((item) => item.id);
    setFlashKeys(keys);
  }, [flashReport]);

  const [selectedIds, setSelectedIds] = useState([[]]); // Initialize with an empty array for each level
  const [subCategories, setSubCategories] = useState([newReportAssignmentData]); // Start with root categories

  useEffect(() => {
    // console.log("selectedIds:", selectedIds[selectedIds.length - 1]);
    const removeEmpty = selectedIds?.filter((item) => item.length > 0);
    const createFlatIds = removeEmpty?.[removeEmpty.length - 1] ?? [];
    // console.log("flat ids", createFlatIds);
    if (createFlatIds?.length === 0) {
      setFilterData(data);
    } else {
      const filterData = findMatches(createFlatIds, data);
      setFilterData(filterData);
    }
  }, [data, selectedIds]);

  const handleCategoryChange = (value, level) => {
    const newSelectedIds = [...selectedIds];
    newSelectedIds[level] = value;
    setSelectedIds(newSelectedIds);

    // Determine sub-categories based on multiple selections
    let newSubCategories = subCategories.slice(0, level + 1); // Keep subcategories up to the current level
    let currentCategories = newReportAssignmentData;

    for (let i = 0; i <= level; i++) {
      const selectedAtLevel = newSelectedIds[i];
      if (selectedAtLevel.length === 0) {
        newSubCategories = newSubCategories.slice(0, i); // Clear subsequent levels if no selection
        break;
      }

      let combinedSubCategories = [];
      selectedAtLevel.forEach((selectedId) => {
        const selectedCategory = currentCategories.find(
          (cat) => cat.id === selectedId
        );
        if (selectedCategory && selectedCategory.sub_categories) {
          combinedSubCategories = [
            ...combinedSubCategories,
            ...selectedCategory.sub_categories,
          ];
        }
      });

      currentCategories = combinedSubCategories;
      if (combinedSubCategories.length > 0) {
        newSubCategories[i + 1] = combinedSubCategories;
      }
    }
    if (newSubCategories.length === 0) {
      setSubCategories([newReportAssignmentData]);
      setSelectedIds([[]]);
    } else {
      setSubCategories(newSubCategories);
    }
  };
  const renderDropdowns = () => {
    return subCategories.map((currentCategories, index) => {
      const sortedCurrentCategories = _.sortBy(currentCategories, "title");
      return (
        <Select
          key={index}
          mode="multiple"
          style={{ width: 300, marginBottom: 16, marginRight: "1rem" }}
          placeholder={`Select ${index === 0 ? "Category" : "Sub-category"}`}
          onChange={(value) => handleCategoryChange(value, index)}
          value={selectedIds[index]}
        >
          {sortedCurrentCategories.map((category) => (
            <Option key={category.id} value={category.id}>
              {category.title}
            </Option>
          ))}
        </Select>
      );
    });
  };

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16} align="middle">
        <Col span={16}>
          <Form.Item
            label={
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                Category:
              </span>
            }
            name="category"
          >
            {renderDropdowns()}
            <Button
              onClick={() => {
                resetSelection();
              }}
              // style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              Reset Selection
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Typography.Title level={5}>Subscription starts</Typography.Title>
          <DatePicker
            value={subscriptionDate}
            onChange={handleSubscriptionDateChange}
            minDate={dayjs()}
          />
        </Col>
        <Col>
          <Typography.Title level={5}>Subscription Ends</Typography.Title>
          <DatePicker
            value={expiryDate}
            onChange={handleExpiryDateChange}
            minDate={dayjs()}
          />
        </Col>
      </Row>
      <Box sx={{ display: "flex", marginBottom: "0.5rem", alignItems: 'center' }}>
      <Typography style={{ marginTop: "10px" }}>
        Total Reports Selected: {flashReport?.length}
      </Typography>
      <Switch
        checkedChildren="Custom On Demand Reports"
        unCheckedChildren="All Reports"
        style={{ marginLeft: "1rem" }}
        defaultChecked={isCustomFlashReport}
        onChange={(checked) => setIsCustomFlashReport(checked)}
        disabled={!selectedUserHasSemiAccess}
      />
      </Box>
      {/* {rowSelection?.selectedRowKeys?.length !== flashReport?.length && <Typography>Current Filter Reports Selected: {rowSelection?.selectedRowKeys?.length}</Typography>} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Button
          type="primary"
          onClick={() => setShowSelected(!showSelected)}
          style={{ margin: "10px 0" }}
        >
          {showSelected ? "Show All" : "Show Selected"}
          {/* {showSelected ? "Show All" : `Show ${rowSelection?.selectedRowKeys?.length !== flashReport?.length ? 'Current' : 'Total'} Selected`} */}
        </Button>
        {/* {rowSelection?.selectedRowKeys?.length !== flashReport?.length && <Typography style={{ color: 'red' }}>Reset Selections to view Total Selected Reports</Typography>} */}
      </div>
      {showSelected ? (
        <div>
          {flashReport?.map((item) => (
            <div
              key={item.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                marginBottom: "5px",
                borderBottom: "1px solid lightgrey",
              }}
            >
              <p>{item.title}</p>
              <Button danger onClick={() => handleRemove(item.id)}>
                Remove
              </Button>
            </div>
          ))}
        </div>
      ) : (
        <Table
          className="custom-table-style"
          style={{ border: "1px solid lightgrey", borderRadius: "10px" }}
          rowSelection={rowSelection}
          columns={columns}
          // dataSource={showSelected ? data.filter(item => flashKeys.includes(item.id)) : data}
          dataSource={filterData}
          loading={loading}
          // pagination={showSelected ? false : { pageSize: 50, showSizeChanger: false }}
          pagination={{ pageSize: 25, showSizeChanger: false }}
          rowKey="id"
        />
      )}
    </Form>
  );
};

export default FlashCreate;
