import React, { useEffect, useState } from "react";

import Loader from "../../components/Common/Loader/Loading";
import {
  useAddPowerBiMutation,
  useEditPowerBiMutation,
} from "../../redux/api/assignReportApi";
import { toast } from "react-toastify";
import { Form, Input, Button, Checkbox, Row, Col, Select } from "antd";
import { useGetNewUserQuery } from "../../redux/api/createUserApi";
import Dashboard from "./../Dashboard/index";
import { LeftCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

const PowerBI = ({ dashboardData, goBack }) => {
  const [form] = Form.useForm();
  const [reports, setReports] = useState([{ reportName: "", reportId: "" }]);
  const [isActive, setIsActive] = useState(true);
  const [clientEmail, setClientEmail] = useState("");
  const [tabName, setTabName] = useState("");
  const handleAddReport = () => {
    setReports([...reports, { reportName: "", reportId: "" }]);
  };

  const handleRemoveReport = (index) => {
    const newReports = reports.filter((_, i) => i !== index);
    setReports(newReports);
  };

  const handleChange = (index, field, value) => {
    const newReports = [...reports];
    const updatedReport = { ...newReports[index], [field]: value };
    newReports[index] = updatedReport;
    setReports(newReports);
  };

  const handleEmailChange = (value) => {
    setClientEmail(value);
  };

  const [
    addPowerBi,
    {
      data: addPowerBiData,
      error: addPowerBiError,
      isLoading: addPowerBiLoading,
    },
  ] = useAddPowerBiMutation();

  const [
    editPowerBi,
    {
      data: editPowerBiData,
      error: editPowerBiError,
      isLoading: editPowerBiLoading,
    },
  ] = useEditPowerBiMutation();

  const { data: synapseUserDetails } = useGetNewUserQuery();

  const handleSubmit = (values) => {
    const reportDetails = reports.map((report) => ({
      reportId: report.reportId,
      reportName: report.reportName,
    }));
    const data = {
      is_active: isActive,
      client_email: clientEmail,
      tab_name: tabName,
      reportDetails,
    };
    if (dashboardData) {
      editPowerBi({
        ...data,
        id: dashboardData.id,
      })
        .then((res) => {
          if (res.error) {
            toast.error(res.error.message);
          } else {
            toast.success("PowerBI updated successfully!");
            form.resetFields();
            setReports([{ reportName: "", reportId: "" }]);
            setIsActive(false);
            setClientEmail("");
            setTabName("")
            goBack();
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
      return;
    }

    addPowerBi(data)
      .then((res) => {
        if (res.error) {
          toast.error(res.error.message);
        } else {
          toast.success(res.data.message);
          form.resetFields();
          setReports([{ reportName: "", reportId: "" }]);
          setIsActive(false);
          setClientEmail("");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  // Function to perform API call for searching files

  useEffect(() => {
    if (dashboardData) {
      //Support edit functionality
      setClientEmail(dashboardData.client_email);
      setReports(dashboardData.reportDetails);
      setIsActive(dashboardData.is_active);
      if(dashboardData.tab_name){
        setTabName(dashboardData.tab_name)
        form.setFieldsValue({
          tabName: dashboardData.tab_name,
        });
      }
      form.setFieldsValue({
        clientEmail: dashboardData.client_email,
      });
      dashboardData.reportDetails.forEach((report, index) => {
        form.setFieldsValue({
          [`reportName-${index}`]: report.reportName,
          [`reportId-${index}`]: report.reportId,
        });
      });
    }
  }, [dashboardData]);

  return (
    <>
      <div style={{ display: "flex", textAlign: "center" }}>
        <>
          <div>
            {dashboardData ? (
              <Button
                type="primary"
                ghost
                style={{ background: "white" }}
                icon={<LeftCircleOutlined />}
                onClick={() => goBack()}
              />
            ) : null}
          </div>
          <h2
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
            }}
          >
            PowerBI Integration
          </h2>
        </>
      </div>
      <div style={{ width: "70%", justifyContent: "center", margin: "auto" }}>
        <Loader show={addPowerBiLoading} />

        <Form form={form} layout="horizontal" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                label="Client Email"
                name="clientEmail"
                rules={[
                  { required: true, message: "Please input client email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
                // wrapperCol={{ span: 14, offset: 1 }}
              >
                <Select
                  showSearch
                  style={{ width: "73.5%", marginLeft: "2.2rem" }}
                  placeholder="Select a client email"
                  optionFilterProp="label"
                  value={clientEmail}
                  onChange={handleEmailChange}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {synapseUserDetails?.data?.map((user) => (
                    <Option
                      key={user.email}
                      value={user.email}
                      label={`${user.name} (${user.email})`}
                    >
                      {user.name} ({user.email})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
            <Form.Item
              // wrapperCol={{ span: 6, offset: 1 }}
              label="Tab Name"
              name={`tabName`}
            // rules={[
            //   { required: true, message: "Please input Tab name!" },
            // ]}
            >
              <Input
                value={tabName}
                style={{ width: "70%", marginLeft: "2rem" }}
                placeholder="Tab name"
                onChange={(e) =>
                  setTabName(e.target.value)
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {reports.map((report, index) => (
          <Row key={index} gutter={16}>
            <Col span={10}>
              <Form.Item
                wrapperCol={{ span: 14 }}
                label="Dashboard Name"
                name={`reportName-${index}`}
                rules={[
                  { required: true, message: "Please input dashboard name!" },
                ]}
              >
                <Input
                  value={report.reportName}
                  onChange={(e) =>
                    handleChange(index, "reportName", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                wrapperCol={{ span: 14 }}
                label="Dashboard ID"
                name={`reportId-${index}`}
                rules={[
                  { required: true, message: "Please input dashboard ID!" },
                ]}
              >
                <Input
                  value={report.reportId}
                  onChange={(e) =>
                    handleChange(index, "reportId", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              {reports.length > 1 && (
                <Button
                  danger
                  onClick={() => handleRemoveReport(index)}
                >
                  Remove
                </Button>
              )}
            </Col>
            <Col span={2}>
              {index === reports.length - 1 && (
                <Button
                  style={{ marginLeft: "1rem" }}
                  // type="dashed"
                  onClick={handleAddReport}
                >
                  Add Dashboard
                </Button>
              )}
            </Col>
          </Row>
        ))}
        {/* <Button
            style={{ marginBottom: "1rem" }}
            type="dashed"
            onClick={handleAddReport}
          >
            Add Dashboard
          </Button> */}
        {/* 
          <Form.Item label="Is Active" valuePropName="checked">
            <Checkbox
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
          </Form.Item> */}

        <Form.Item
          style={{
            textAlign: "center",
          }}
        >
          {dashboardData ? (
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div >
    </>
  );
};

export default PowerBI;
