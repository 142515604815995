import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Tooltip, message } from "antd";
import API from "../../utils/api";
import { Box, Button, Tab, Tabs } from "@mui/material";
import {
  assignReport,
  clearAssignedReportState as clearAssignedReports,
} from "../../redux/api/assignedReportsNewSlice";
import {
  clearFlashReportState as clearFlashReports,
  setFlashReports,
} from "../../redux/flashReportAssignment";
import {
  setEmail,
  clearState as clearSelectedUser,
} from "../../redux/selectedUserSlice";
import AssignReportForm from "./AssignReportForm";
import FlashCreate from "./FlashCreate";
import LibraryCreate from "./LibraryCreate";
import {
  updateLibraryFormDetails,
  updateSelectedReport,
} from "../../redux/libraryAssignmentMain";
import dayjs from "dayjs";
import { checkStores } from "./ReduxDataCheck";
import Preloader from "../../components/Preloader/Preloader";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ReportAssignment = ({
  handleBack,
  selectedUserEmail,
  handleBackReportAssignment,
  selectedUserName,
  selectedCustomReportUser,
  selectedUserHasSemiAccess,
}) => {
  const storedEmail = useSelector((store) => store.selectedUser.email);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { Base_URL, getHubList, userDataReportAssignment } = API;
  let token = cookies.get("TOKEN");
  const selectedLibraryReport = useSelector(
    (state) => state.libraryAssignmentMain.selectedReport
  );
  const libraryFormFields = useSelector(
    (state) => state.libraryAssignmentMain.libraryFormDetails
  );

  let config = {
    method: "get",
    headers: { authorization: `Bearer ${token}` },
    maxBodyLength: Infinity,
    url: `${Base_URL}${getHubList}`,
  };
  const [loading, setLoading] = useState(false);
  const [hubList, setHubList] = useState([]);
  const [showReviewData, setShowReviewData] = useState(false);

  const hubsReport = useSelector((state) => state.assignedReportsNew);
  const flashReport = useSelector((state) => state.flashReportAssignment.flash);
  const selectedReportData = useSelector(
    (state) => state.libraryAssignmentMain.selectedReport
  );
  const libraryFormDetails = useSelector(
    (state) => state.libraryAssignmentMain.libraryFormDetails
  );

  useEffect(() => {
    axios(config).then((res) => {
      setHubList(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    const isDisabled = checkStores(
      hubsReport,
      flashReport,
      selectedReportData,
      libraryFormDetails
    );
    setShowReviewData(!isDisabled);
  }, [hubsReport, flashReport, selectedReportData, libraryFormDetails]);

  useEffect(() => {
    if (storedEmail !== selectedUserEmail) {
      dispatch(clearSelectedUser());
      dispatch(clearAssignedReports());
      dispatch(clearFlashReports());
      dispatch(setEmail(selectedUserEmail));
    }
  }, [selectedUserEmail, storedEmail, dispatch]);

  const [searchData, setSearchData] = useState({
    hubSelect: "",
    accessType: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({
      ...searchData,
      [name]: value,
    });
  };

  const convertToReduxFormat = (dateString) => {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset() * 60000; // Get the offset in milliseconds
    const ISTOffset = 19800000; // IST offset in milliseconds (UTC+5:30)
    const ISTDate = new Date(date.getTime() + ISTOffset - offset);
    return ISTDate.toISOString();
  };

  const assignHubsFunction = (hubs) => {
    if (hubs?.length === 0) return;

    hubs.forEach((hub) => {
      const {
        selected_hubs,
        access_type,
        modules,
        subscription_date,
        expiry_date,
        reports_arr,
      } = hub;
      const formattedSubscriptionDate = subscription_date
        ? convertToReduxFormat(subscription_date)
        : null;
      const formattedExpiryDate = expiry_date
        ? convertToReduxFormat(expiry_date)
        : null;

      const payload = {
        access_type,
        modules,
        selectHub: selected_hubs,
        subscription_date: formattedSubscriptionDate,
        expiry_date: formattedExpiryDate,
      };
      if (reports_arr && reports_arr.length > 0) {
        payload.report = reports_arr.map((report) => ({
          id: report.id,
          title: report.title,
          subscription_date:
            report?.subscription_date !== "N/A" || null
              ? convertToReduxFormat(report.subscription_date)
              : null,
          expiry_date:
            report?.expiry_date !== "N/A" || null
              ? convertToReduxFormat(report.expiry_date)
              : null,
        }));
      }
      dispatch(assignReport(payload));
    });
  };

  useEffect(() => {
    if (!userDataReportAssignment) return;
    // if (storedEmail === selectedUserEmail) return;
    if (sessionStorage.getItem("callApi") === "false") return;
    setLoading(true);
    dispatch(clearAssignedReports());
    dispatch(clearFlashReports());
    // Fetch hub list
    const config = {
      method: "get",
      headers: { authorization: `Bearer ${token}` },
      url: `${Base_URL}${userDataReportAssignment}`,
      params: { email: selectedUserEmail },
    };

    axios(config)
      .then((res) => {
        // const hubList = res?.data?.data;
        // const sortedData = _.sortBy(hubList, "name");
        assignHubsFunction(res?.data?.hubs);
        dispatch(setFlashReports(res?.data?.flash));
        const clonedSelectedLibraryReport = [...selectedLibraryReport];

        res?.data?.library?.reports_arr?.forEach((item) => {
          const currentReportId = item?.synapse_report_id;
          //If the report is already present in the selectedLibraryReport array, then update the report
          const index = clonedSelectedLibraryReport.findIndex(
            (report) => report.id === currentReportId
          );
          if (index !== -1) {
            clonedSelectedLibraryReport[index] = {
              ...clonedSelectedLibraryReport[index],
              ...item,
              subscription_date: dayjs(item.subscription_date),
              expiry_date: dayjs(item.expiry_date),
            };
            return;
          }
          //If the report is not present in the selectedLibraryReport array, then add the report
          clonedSelectedLibraryReport.push({
            ...item,
            id: currentReportId,
            subscription_date: dayjs(item.subscription_date),
            expiry_date: dayjs(item.expiry_date),
          });
        });

        dispatch(updateSelectedReport({ data: clonedSelectedLibraryReport }));

        if (Object.keys(libraryFormFields).length === 0) {
          dispatch(
            updateLibraryFormDetails({
              key: "hubAccessType",
              value: res?.data?.library?.access_type,
            })
          );
          if (res?.data?.library?.subscription_date) {
            dispatch(
              updateLibraryFormDetails({
                key: "subscription_date",
                value: dayjs(res?.data?.library?.subscription_date),
              })
            );
          }
          if (res?.data?.library?.expiry_date) {
            dispatch(
              updateLibraryFormDetails({
                key: "expiry_date",
                value: dayjs(res?.data?.library?.expiry_date),
              })
            );
          }
        }
      })
      .catch((error) => {
        console.log("Failed to load");
      })
      .finally(() => {
        setLoading(false);
        sessionStorage.setItem("callApi", false);
      });
  }, [Base_URL, token, userDataReportAssignment]);

  const [value, setValue] = React.useState(0);

  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div>
          <div
            className="flex section-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip message="Go Back" onClick={handleBack}>
              <ArrowLeftOutlined type="arrow-left" className="back-arrow-btn" />
            </Tooltip>
            <h2>{selectedUserName || selectedUserEmail}</h2>
            <Button
              style={{
                background: showReviewData ? "blue" : "grey",
                color: "white",
              }}
              disabled={!showReviewData}
              onClick={handleBackReportAssignment}
            >
              Review The Data
            </Button>
          </div>
          <Tabs
            sx={{ boxShadow: "#002f7514 0px 2px 20px 0px" }}
            value={value}
            onChange={tabHandleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Hubs" {...a11yProps(0)} />
            <Tab label="Flash" {...a11yProps(1)} />
            <Tab label="Library" {...a11yProps(2)} />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <AssignReportForm selectedUserEmail={selectedUserEmail} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <FlashCreate 
              selectedUserEmail={selectedUserEmail} 
              selectedCustomReportUser={selectedCustomReportUser} 
              selectedUserHasSemiAccess={selectedUserHasSemiAccess}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <LibraryCreate
              userEmail={selectedUserEmail}
              selectedCustomReportUser={selectedCustomReportUser}
              selectedUserHasSemiAccess={selectedUserHasSemiAccess}
            />
          </CustomTabPanel>
        </div>
      )}
    </>
  );
};

export default ReportAssignment;
